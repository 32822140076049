import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListNetStationRequest } from '../types/INetStation';
import {
    IAddTicketCommentRequest,
    IAddTicketRequest,
    IGetListTicketCommentsRequest,
    IGetListTicketRequest,
    IRatingTicketRequest
} from '../types/ITicket';
import BaseAuthService from './BaseAuthService';

export class TicketService extends BaseAuthService {
    private static instance: TicketService;

    public static getInstance(): TicketService {
        if (!TicketService.instance) {
            TicketService.instance = new TicketService();
        }
        return TicketService.instance;
    }

    getListTicket = async (params: IGetListTicketRequest) => {
        try {
            const response = await this.postData(API.TICKET.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListTicketFFood = async (params: IGetListTicketRequest) => {
        try {
            const response = await this.postData(API.TICKET.FFOOD_LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    addTicket = async (params: IAddTicketRequest) => {
        try {
            const response = await this.postData(API.TICKET.ADD_TICKET, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    ratingTicket = async (params: IRatingTicketRequest) => {
        try {
            const response = await this.postData(API.TICKET.RATING, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListTicketComment = async (params: IGetListTicketCommentsRequest) => {
        try {
            const response = await this.postData(
                API.TICKET.COMMENT_LIST,
                params
            );
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListTicketCommentFFood = async (
        params: IGetListTicketCommentsRequest
    ) => {
        try {
            const response = await this.postData(
                API.TICKET.FFOOD_COMMENT_LIST,
                params
            );
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    commentTicket = async (params: IAddTicketCommentRequest) => {
        try {
            const response = await this.postData(
                API.TICKET.ADD_COMMENT,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    commentTicketFFood = async (params: IAddTicketCommentRequest) => {
        try {
            const response = await this.postData(
                API.TICKET.FFOOD_ADD_COMMENT,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
