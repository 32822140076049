import { action, makeObservable, observable, runInAction } from 'mobx';
import { ITicket, TICKET_ACTION, TICKET_STATUS } from '../types/ITicket';
import { DataList } from './DataList';

export default class Ticket {
    public ticketId: string;
    public netId: string;
    public netName: string;
    public netCode: string;
    public address: string;
    public owner: string;
    public phone: string;
    public cityName: string;
    public districtName: string;
    public createdBy: string;
    public ultraAccount: string; //thông tin remote ultra view
    public ultraPass: string;
    @observable public status: TICKET_STATUS;
    public contentRequest: string;
    @observable public rating: number;
    @observable public ratingContent: string; // nội dung đánh giá
    public ratingBy: string; // người đánh giá
    public ratingDate: string; // ngày đánh giá
    public supportBy: string; // thông tin OS hỗ trợ
    public supportDate: string;
    @observable public isRanking: boolean;
    @observable public isComment: boolean;
    public createDate: string;
    @observable public statusName: string;

    constructor(
        ticketId: string,
        netId: string,
        netName: string,
        netCode: string,
        address: string,
        owner: string,
        phone: string,
        cityName: string,
        districtName: string,
        createdBy: string,
        ultraAccount: string, //thông tin remote ultra view,
        ultraPass: string,
        status: TICKET_STATUS,
        contentRequest: string,
        rating: number,
        ratingContent: string, // nội dung đánh giá,
        ratingBy: string, // người đánh giá,
        ratingDate: string, // ngày đánh giá,
        supportBy: string, // thông tin OS hỗ trợ,
        supportDate: string,
        isRanking: boolean,
        isComment: boolean,
        createDate: string,
        statusName: string
    ) {
        makeObservable(this);
        this.ticketId = ticketId;
        this.netId = netId;
        this.netName = netName;
        this.netCode = netCode;
        this.address = address;
        this.owner = owner;
        this.phone = phone;
        this.cityName = cityName;
        this.districtName = districtName;
        this.createdBy = createdBy;
        this.ultraAccount = ultraAccount;
        this.ultraPass = ultraPass;
        this.status = status;
        this.contentRequest = contentRequest;
        this.rating = rating;
        this.ratingContent = ratingContent;
        this.ratingBy = ratingBy;
        this.ratingDate = ratingDate;
        this.supportBy = supportBy;
        this.supportDate = supportDate;
        this.isRanking = isRanking;
        this.isComment = isComment;
        this.createDate = createDate;
        this.statusName = statusName;
    }
    static fromJson = (json: ITicket): Ticket => {
        return new Ticket(
            json.ticketId,
            json.netId,
            json.netName,
            json.netCode,
            json.address,
            json.owner,
            json.phone,
            json.cityName,
            json.districtName,
            json.createdBy,
            json.ultraAccount,
            json.ultraPass,
            json.status,
            json.contentRequest,
            json.rating,
            json.ratingContent,
            json.ratingBy,
            json.ratingDate,
            json.supportBy,
            json.supportDate,
            json.isRanking,
            json.isComment,
            json.createDate,
            json.statusName
        );
    };

    @action updateRatingStatus = (
        rating: number,
        ratingContent: string
    ): void => {
        runInAction(() => {
            this.isRanking = false;
            this.rating = rating;
            this.ratingContent = ratingContent;
        });
    };

    @action updateCommentStatus = (value: boolean): void => {
        runInAction(() => {
            this.isComment = value;
        });
    };

    @action updateCloseTicket = () => {
        runInAction(() => {
            this.status = TICKET_STATUS.COMPLETED;
            this.statusName = 'Hoàn thành';
        });
    };

    @action updateActionTicket = (action: TICKET_ACTION) => {
        runInAction(() => {
            if (action === TICKET_ACTION.ACCEPT) {
                this.status = TICKET_STATUS.PROCESSING;
                this.statusName = 'Đang xử lý';
            } else if (action === TICKET_ACTION.CANCEL) {
                this.status = TICKET_STATUS.CANCEL;
                this.statusName = 'Hủy bỏ';
            } else {
                this.status = TICKET_STATUS.COMPLETED;
                this.statusName = 'Hoàn thành';
            }
        });
    };
}

export class ListTicket extends DataList<Ticket> {
    static fromJson = (jsonArray: Array<ITicket>): Array<Ticket> => {
        if (!jsonArray) return [];
        let data = new Array<Ticket>();
        jsonArray.forEach((item) => {
            data.push(Ticket.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
