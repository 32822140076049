import { ComponentType } from 'react';
import pure from 'recompose/pure';
import {
    FilterContentWrapper,
    InputText,
    InputWrapper,
    LabelText,
    SearchButton,
    SearchButtonWrapper,
    StatusNetStationFilter,
    NetStationFilterContainer,
    NetStationFilterWrapper
} from './NetStationFilter.styles';
import { Col, Collapse, Row, Select } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { Controller, useForm } from 'react-hook-form';
import {
    IGetListNetStationRequest,
    INetStation,
    INetStationFilter
} from '../../../types/INetStation';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { APIResponseList } from '../../../models/APIResponse';

//components

//styles

interface Props {
    onFilter: (data: INetStationFilter) => void;
}

const { Panel } = Collapse;

export const NetStationFilter: ComponentType<Props> = pure(({ onFilter }) => {
    const store = useRootStore();
    const { getListNetStation, netStationFilter, updateNetStationFilter } =
        store.netStationStore;
    const {
        register,
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { errors }
    } = useForm<INetStationFilter>({
        defaultValues: {
            netId: netStationFilter ? netStationFilter.netId : '',
            netCode: netStationFilter ? netStationFilter.netCode : '',
            status: netStationFilter ? netStationFilter.status : 3 //all netstation filters
        }
    });

    const onSubmit = async (data: INetStationFilter) => {
        updateNetStationFilter(data);
        onFilter(data);
    };

    return (
        <NetStationFilterWrapper>
            <NetStationFilterContainer defaultActiveKey={1}>
                <Panel header="Tìm kiếm phòng máy" key={1}>
                    <FilterContentWrapper>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col xl={6} md={6} sm={12} xs={24}>
                                    <InputWrapper>
                                        {/* <LabelText>Mã yêu cầu</LabelText> */}
                                        <InputText
                                            placeholder="FUID phòng máy"
                                            type="text"
                                            {...register('netId')}
                                        />
                                    </InputWrapper>
                                </Col>
                                <Col xl={6} md={6} sm={12} xs={24}>
                                    <InputWrapper>
                                        {/* <LabelText>Điện thoại khách hàng</LabelText> */}
                                        <InputText
                                            placeholder="MC"
                                            type="text"
                                            {...register('netCode')}
                                        />
                                    </InputWrapper>
                                </Col>
                                <Col xl={6} md={6} sm={12} xs={24}>
                                    <InputWrapper>
                                        {/* <LabelText>Điện thoại khách hàng</LabelText> */}
                                        <Controller
                                            control={control}
                                            name="status"
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { invalid, error },
                                                formState
                                            }) => (
                                                <StatusNetStationFilter
                                                    defaultValue={0}
                                                    style={{ width: '100%' }}
                                                    value={value}
                                                    onChange={onChange}
                                                    options={[
                                                        {
                                                            value: 0,
                                                            label: '-- Tất cả phòng máy --'
                                                        },
                                                        {
                                                            value: 1,
                                                            label: 'Sắp hết hạn (<= 7 ngày)'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Hết hạn'
                                                        },
                                                        {
                                                            value: 3,
                                                            label: 'Còn hạn'
                                                        }
                                                    ]}
                                                />
                                            )}
                                        />
                                    </InputWrapper>
                                </Col>
                                <SearchButtonWrapper
                                    xl={6}
                                    md={6}
                                    sm={12}
                                    xs={24}>
                                    <SearchButton
                                        type="submit"
                                        typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                        Tìm kiếm phòng máy
                                    </SearchButton>
                                </SearchButtonWrapper>
                            </Row>
                        </form>
                    </FilterContentWrapper>
                </Panel>
            </NetStationFilterContainer>
        </NetStationFilterWrapper>
    );
});
