import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { IResponseBase } from '../types/ITypeBase';
import {
    IAddTicketCommentRequest,
    IAddTicketRequest,
    IGetListTicketCommentsRequest,
    IGetListTicketRequest,
    IRatingTicketRequest,
    ITicket,
    ITicketComment,
    ITicketFilter
} from '../types/ITicket';
import { TicketService } from '../services/TicketService';
import { ListTicket } from '../models/Ticket';
import TicketComment, { ListTicketComment } from '../models/TicketComment';

class TicketStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listTicket: ListTicket | null = null;
    @observable listTicketComment: ListTicketComment | null = null;
    @observable ticketFilter: ITicketFilter | null = null;

    @action updateTicketFilter = (filter: ITicketFilter) => {
        runInAction(() => {
            this.ticketFilter = filter;
        });
    };

    @action getListTicket = async (
        params: IGetListTicketRequest,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listTicket)
            runInAction(() => {
                this.listTicket = new ListTicket();
            });
        else if (loadMore && !pageIndex) {
            this.listTicket.pageIndex += 1;
        } else if (loadMore && pageIndex != 0) {
            this.listTicket.pageIndex = pageIndex! - 1;
        }

        if (this.listTicket) {
            this.listTicket.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listTicket.pageIndex
                : this.listTicket.pageSize * this.listTicket.pageIndex;
        }

        //request api service
        const result = (await TicketService.getInstance().getListTicket(
            params
        )) as APIResponseList<Array<ITicket>>;

        //check result and binding data if success

        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listTicket) {
                    if (loadMore && !pageIndex)
                        this.listTicket.list = this.listTicket.list?.concat(
                            ListTicket.fromJson(result.data?.list!)
                        )!;
                    else
                        this.listTicket.list = ListTicket.fromJson(
                            result.data?.list!
                        );
                    this.listTicket.totalRecord = result.totalRecord();
                    this.listTicket.isLoading = false;
                }
            });
        } else if (this.listTicket) this.listTicket.isLoading = false;
        return result;
    };

    @action getListTicketFFood = async (
        params: IGetListTicketRequest,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listTicket)
            runInAction(() => {
                this.listTicket = new ListTicket();
            });
        else if (loadMore && !pageIndex) {
            this.listTicket.pageIndex += 1;
        } else if (loadMore && pageIndex != 0) {
            this.listTicket.pageIndex = pageIndex! - 1;
        }

        if (this.listTicket) {
            this.listTicket.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listTicket.pageIndex
                : this.listTicket.pageSize * this.listTicket.pageIndex;
        }

        //request api service
        const result = (await TicketService.getInstance().getListTicketFFood(
            params
        )) as APIResponseList<Array<ITicket>>;

        //check result and binding data if success

        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listTicket) {
                    if (loadMore && !pageIndex)
                        this.listTicket.list = this.listTicket.list?.concat(
                            ListTicket.fromJson(result.data?.list!)
                        )!;
                    else
                        this.listTicket.list = ListTicket.fromJson(
                            result.data?.list!
                        );
                    this.listTicket.totalRecord = result.totalRecord();
                    this.listTicket.isLoading = false;
                }
            });
        } else if (this.listTicket) this.listTicket.isLoading = false;
        return result;
    };

    @action addCommentToList = (comment: TicketComment) => {
        runInAction(() => {
            if (this.listTicketComment)
                this.listTicketComment.list =
                    this.listTicketComment.list?.concat(comment);
        });
    };

    @action getListTicketComment = async (
        params: IGetListTicketCommentsRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listTicketComment)
            runInAction(() => {
                this.listTicketComment = new ListTicketComment();
            });
        else if (loadMore) {
            this.listTicketComment.pageIndex += 1;
            this.listTicketComment.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listTicketComment.pageIndex
                : this.listTicketComment.pageSize *
                  this.listTicketComment.pageIndex;
        }

        //request api service
        const result = (await TicketService.getInstance().getListTicketComment(
            params
        )) as APIResponseList<Array<ITicketComment>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listTicketComment) {
                    this.listTicketComment.list =
                        this.listTicketComment.list?.concat(
                            ListTicketComment.fromJson(result.data?.list!)
                        )!;
                    this.listTicketComment.totalRecord = result.totalRecord();
                    this.listTicketComment.isLoading = false;
                }
            });
        }
        return result;
    };

    @action getListTicketCommentFFood = async (
        params: IGetListTicketCommentsRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listTicketComment)
            runInAction(() => {
                this.listTicketComment = new ListTicketComment();
            });
        else if (loadMore) {
            this.listTicketComment.pageIndex += 1;
            this.listTicketComment.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listTicketComment.pageIndex
                : this.listTicketComment.pageSize *
                  this.listTicketComment.pageIndex;
        }

        //request api service
        const result =
            (await TicketService.getInstance().getListTicketCommentFFood(
                params
            )) as APIResponseList<Array<ITicketComment>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listTicketComment) {
                    this.listTicketComment.list =
                        this.listTicketComment.list?.concat(
                            ListTicketComment.fromJson(result.data?.list!)
                        )!;
                    this.listTicketComment.totalRecord = result.totalRecord();
                    this.listTicketComment.isLoading = false;
                }
            });
        }
        return result;
    };

    @action addTicket = async (params: IAddTicketRequest) => {
        const result = (await TicketService.getInstance().addTicket(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action commentTicket = async (params: IAddTicketCommentRequest) => {
        const result = (await TicketService.getInstance().commentTicket(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action commentTicketFFood = async (params: IAddTicketCommentRequest) => {
        const result = (await TicketService.getInstance().commentTicketFFood(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action ratingTicket = async (params: IRatingTicketRequest) => {
        const result = (await TicketService.getInstance().ratingTicket(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };
}

export default TicketStore;
