import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { Collapse, Rate } from 'antd';
import { AppButton } from '../../../styles/AppButton.styles';

export const TicketItemWrapper = styled.div`
    :not(:last-child) {
        border-bottom: 1px solid #9fa3ab;
    }
    padding: 10px;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin-left: 40px;
        margin-right: 40px;
    }
`;

export const TicketItemContainer = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: column;
        //align-items: center;
    }
`;

export const TicketInfoContainer = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const TicketInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        width: 100%;
        margin-right: 30px;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    padding-bottom: 10px;

    @media ${device.mobileS} {
        flex-direction: column;
        align-items: start;
    }
    @media ${device.laptop} {
        flex-direction: row;
        align-items: center;
    }
`;

export const CopyWrapper = styled.div`
    display: flex;
`;

export const TitleText = styled.h1`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
`;

export const TitleTextL2 = styled.h1`
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    word-wrap: break-word;
`;

export const CommentText = styled.p`
    margin: 0;
    color: #8d9199;
    font-size: 13px;
    font-style: italic;
`;

export const DescriptionText = styled.h2`
    margin: 0;
    font-size: 14px;
    min-width: 160px;
    font-weight: 500;
    color: #8d9199;
`;

export const NetStationInfoWrapper = styled(Collapse)`
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: solid 1px #cfd4d1;
    .ant-collapse-item {
        border: 0 !important;
    }
    .ant-collapse-header {
        background-color: #edf0ee;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
    .ant-collapse-content {
        border: 0 !important;
        border-radius: 10px !important;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
`;

export const RatingWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
        align-items: center;
    }
`;

export const RatingContentWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const RatingButton = styled(AppButton)`
    height: 40px !important;

    font-size: 14px !important;
    margin-right: 10px;

    @media ${device.mobileS} {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    @media ${device.laptop} {
        margin: 0;
        width: 150px;
        margin-right: 10px;
    }
`;

export const CloseButton = styled(RatingButton)`
    width: 100px;
    color: #fff !important;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin: 0;
        margin-right: 10px;
    }
`;

export const AcceptButton = styled(RatingButton)`
    width: 100px;
    color: #fff !important;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin: 0;
        margin-right: 10px;
    }
`;

export const CancelButton = styled(RatingButton)`
    width: 100px;
    color: #fff !important;
    background-color: #eb7434 !important;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin: 0;
        margin-right: 10px;
    }
`;

export const CommentButton = styled(RatingButton)`
    width: 60px;
    color: #336699 !important;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin: 0;
    }
`;

export const RatingStart = styled(Rate)`
    margin-right: 20px;
    svg {
        width: 20px !important;
        height: 20px !important;
    }
    .ant-rate-star:not(:last-child) {
        margin-right: 2px;
    }
`;
