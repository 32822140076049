import { ITicketComment } from '../types/ITicket';
import { DataList } from './DataList';

export default class TicketComment {
    public ticketId: string;
    public commentId: string;
    public userId: string;
    public userName: string;
    public fullName: string;
    public commentDate: string;
    public commentContent: string;

    constructor(
        ticketId: string,
        commentId: string,
        userId: string,
        userName: string,
        fullName: string,
        commentDate: string,
        commentContent: string
    ) {
        this.ticketId = ticketId;
        this.commentId = commentId;
        this.userId = userId;
        this.userName = userName;
        this.fullName = fullName;
        this.commentDate = commentDate;
        this.commentContent = commentContent;
    }

    static fromJson = (json: ITicketComment): TicketComment => {
        return new TicketComment(
            json.ticketId,
            json.commentId,
            json.userId,
            json.userName,
            json.fullName,
            json.commentDate,
            json.commentContent
        );
    };
}

export class ListTicketComment extends DataList<TicketComment> {
    static fromJson = (
        jsonArray: Array<ITicketComment>
    ): Array<TicketComment> => {
        if (!jsonArray) return [];
        let data = new Array<TicketComment>();
        jsonArray.forEach((item) => {
            data.push(TicketComment.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
