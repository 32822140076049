import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    account: Yup.string().required('Email is required'),
    //.email('Email is invalid'),
    password: Yup.string()
        .required('Password is required')
        .min(5, 'Password must be at least 6 characters')
        .max(40, 'Password must not exceed 40 characters')
});
