import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    AvatarImage,
    AvatarWrapper,
    CommentContent,
    CommentDate,
    CommentInfoWrapper,
    CommentItemContainer,
    CommentItemWrapper,
    UserName
} from './CommentItem.styles';
import { humanTime } from '../../../../utils/DateUtils';
import TicketComment from '../../../../models/TicketComment';

interface Props {
    data: TicketComment;
}

export const CommentItem: ComponentType<Props> = pure(({ data }) => {
    return (
        <CommentItemWrapper>
            <CommentItemContainer>
                <AvatarWrapper>
                    <AvatarImage
                        src={''}
                        placeholder={true}
                        preview={false}
                        fallback="../../../../images/default-avatar.png"></AvatarImage>
                </AvatarWrapper>
                <CommentInfoWrapper>
                    <UserName>{data.fullName}</UserName>
                    <CommentContent>{data.commentContent}</CommentContent>
                    <CommentDate>
                        {humanTime(data.commentDate, 'vi')}
                    </CommentDate>
                </CommentInfoWrapper>
            </CommentItemContainer>
        </CommentItemWrapper>
    );
});
