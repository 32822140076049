import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    width: 100%;
    min-height: 70px;
    background-color: #19181c;
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

export const AppLogoContainer = styled.div`
    width: 110px;
    height: 40px;
    position: absolute;
    left: 50%;
    transform: translate(-42%, 50%);
    padding-top: 15px;
`;

export const AppLogoImage = styled.img`
    object-fit: cover;
    max-width: 100%;
`;

export const MenuWrapper = styled.div`
    position: absolute;
    width: 28px;
    height: 21px;
    top: 25px;
    right: 20px;
`;
