import React, { ComponentType, FC, ReactNode, useState } from 'react';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FormOutlined, PlusOutlined } from '@ant-design/icons';
import { AddTicketDialog } from '../../views/DialogView/AddTicketDialog/AddTicketDialog';
import { useRootStore } from '../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
// define interface to represent component props
interface Props {}

const MainLayout: ComponentType<Props> = observer(() => {
    const store = useRootStore();
    const location = useLocation();

    const { isDialogAddTicketActive } = store.globalStore;

    const [showAddTicket, setShowAddTicket] = useState(false);

    const onRequestAddTicket = async () => {
        if (isDialogAddTicketActive == false) setShowAddTicket(true);
    };

    return (
        <div>
            <Header></Header>
            <Outlet />
            {location.pathname.toLowerCase() != '/ffood-ticket' ? (
                <Fab
                    alwaysShowTitle={false}
                    text="Thêm yêu cầu hỗ trợ"
                    onClick={() => onRequestAddTicket()}
                    mainButtonStyles={{
                        backgroundColor: '#eb4034',
                        cursor: 'pointer'
                    }}
                    icon={<PlusOutlined />}></Fab>
            ) : null}
            <Footer />

            <AddTicketDialog
                //netStation={null}
                visible={showAddTicket}
                onClose={() => setShowAddTicket(false)}
            />
        </div>
    );
});

export default MainLayout;
