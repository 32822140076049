import styled from 'styled-components';
import { AppButton } from '../../../../styles/AppButton.styles';
import { device } from '../../../../configs/MediaQuery';
import Image from 'rc-image';
import { Dropdown } from 'antd';

export const UserLoginWrapper = styled.div`
    background-color: #19181c;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const UserLoginContainer = styled.div``;

export const LoginButton = styled(AppButton)``;

export const AvatarWrapper = styled.div`
    position: relative;
    display: flex;

    @media ${device.mobileS} {
        margin-bottom: 20px;
    }
    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`;

export const AvatarImage = styled(Image)`
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 20px;
    border: solid 2px #fff;
    position: relative;
    margin-right: 20px;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const DropdownProfile = styled(Dropdown)`
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    color: #54b4a4;
    :hover {
        color: #54b4a4;
    }

    .anticon-down {
        font-weight: 700;
        font-size: 16px;
    }
`;

export const ProfileMenuWrapper = styled.div`
    color: #fff;
    background-color: #000;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ProfileMenuItem = styled.div`
    padding: 10px 20px 10px 20px;
    border-bottom: solid 2px #252525;
`;

export const ProfileMenuText = styled.p`
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    :hover {
        color: #54b4a4;
    }
`;

export const LogoutButton = styled(AppButton)`
    height: 40px;
    width: 150px;
`;
