import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';

//components
import { TicketItem } from './TicketItem/TicketItem';
import { TicketFilter } from './TicketFilter/TicketFilter';
//styles
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';
import {
    LoadingText,
    LoadingWrapper,
    TicketListWrapper,
    TicketTitle
} from './FFoodTicketView.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../types/IGlobal';
import { TicketCommentFFoodDialog } from '../DialogView/TicketCommentFFoodDialog/TicketCommentFFoodDialog';
import { RatingDialog } from '../DialogView/RatingDialog/RatingDialog';
import {
    IGetListTicketRequest,
    ITicketDialogData,
    ITicketFilter,
    TICKET_ACTION
} from '../../types/ITicket';
import Ticket from '../../models/Ticket';
import { Pagination, PaginationProps, Spin } from 'antd';
import { PaginationWrapper } from '../NetStationView/NetStationView.styles';
import { NoData } from '../CommonView/NoData/NoData';
import { TicketCloseFFoodDialog } from '../DialogView/TicketCloseFFoodDialog/TicketCloseFFoodDialog';

interface Props {}

export const FFoodTicketView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { changeMainMenuIndex } = store.globalStore;
    const { listTicket, ticketFilter, getListTicketFFood } = store.ticketStore;

    const [ticketData, setTicketData] = useState<Ticket>();
    const [openCommentDialog, setOpenCommentDialog] = useState(false);
    const [openRatingTicket, setOpenRatingTicket] = useState(false);
    const [openTicketCloseDialog, setOpenTicketCloseDialog] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [description, setDescription] = useState('');
    const [dialogData, setDialogData] = useState<ITicketDialogData>();

    useEffect(() => {
        changeMainMenuIndex(MAIN_MENU.FFOOD_TICKET);
        onGetListTicket();
    }, [ticketFilter]);

    const onOpenCommentDialog = (ticket: Ticket) => {
        setTicketData(ticket);
        setOpenCommentDialog(true);
    };

    const onOpenRatingDialog = (ticket: Ticket) => {
        setTicketData(ticket);
        setOpenRatingTicket(true);
    };

    const onActionTicket = (ticket: Ticket, dialogData: ITicketDialogData) => {
        setTicketData(ticket);
        setDialogData(dialogData);
        setOpenTicketCloseDialog(true);
    };

    const onGetListTicket = async (
        isLoadMore?: boolean,
        pageIndex?: number
    ) => {
        const params: IGetListTicketRequest = {
            data: {
                ticketId: ticketFilter ? ticketFilter?.ticketId : '',
                phone: ticketFilter ? ticketFilter?.phone : '',
                status: ticketFilter ? ticketFilter?.status : -1
            }
        };
        const result = await getListTicketFFood(params, isLoadMore, pageIndex);
        setDescription(result.getDescription());
    };

    const onChangePage: PaginationProps['onChange'] = (page, pageSize) => {
        setPageIndex(page);
        onGetListTicket(true, page);
    };

    const renderData = () => {
        if (listTicket && listTicket.hasData()) {
            return (
                <TicketListWrapper>
                    <TicketTitle>
                        Danh sách yêu cầu (
                        {listTicket.totalRecord.toString().padStart(2, '0')})
                    </TicketTitle>
                    {listTicket.list.map((item) => {
                        return (
                            <TicketItem
                                key={item.ticketId}
                                data={item}
                                openCommentDialog={onOpenCommentDialog}
                                openRatingDialog={onOpenRatingDialog}
                                actionTicket={onActionTicket}
                            />
                        );
                    })}
                    <PaginationWrapper>
                        <Pagination
                            showSizeChanger={false}
                            defaultCurrent={pageIndex}
                            total={listTicket.totalPage}
                            onChange={onChangePage}
                        />
                    </PaginationWrapper>
                </TicketListWrapper>
            );
        } else return null;
    };

    const renderLoading = () => {
        if (listTicket && listTicket.isLoading)
            return (
                <LoadingWrapper>
                    <Spin></Spin>
                    <LoadingText>Đang tải dữ liệu</LoadingText>
                </LoadingWrapper>
            );
        else return null;
    };

    const renderNoData = () => {
        if (!listTicket?.hasData() && !listTicket?.isLoading)
            return <NoData description={description} />;
        else return null;
    };

    const onFilterSuccess = async (data: ITicketFilter) => {
        setPageIndex(1);
        const params: IGetListTicketRequest = {
            data: {
                ticketId: data.ticketId,
                phone: data.phone,
                status: data.status
            }
        };
        const result = await getListTicketFFood(params);
        setDescription(result.getDescription());
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TicketFilter onFilter={onFilterSuccess} />
                {renderLoading()}
                {renderData()}
                {renderNoData()}
            </AppContentContainer>
            {openCommentDialog && ticketData ? (
                <TicketCommentFFoodDialog
                    ticket={ticketData}
                    visible={openCommentDialog}
                    //onCommentSuccess={() => onGetListTicket()}
                    onClose={() => setOpenCommentDialog(false)}
                />
            ) : null}

            {openRatingTicket && ticketData ? (
                <RatingDialog
                    ticket={ticketData}
                    visible={openRatingTicket}
                    onClose={() => setOpenRatingTicket(false)}
                />
            ) : null}

            {openTicketCloseDialog && ticketData && dialogData ? (
                <TicketCloseFFoodDialog
                    ticket={ticketData}
                    dialogData={dialogData}
                    visible={openTicketCloseDialog}
                    onClose={() => setOpenTicketCloseDialog(false)}
                />
            ) : null}
        </AppContentWrapper>
    );
});
