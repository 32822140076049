import { ComponentType } from 'react';
import pure from 'recompose/pure';
import {
    AppContentContainer,
    AppContentWrapper
} from '../../../styles/App.styles';
import {
    ContentDescription,
    ContentTitle,
    NoDataContainer,
    NoDataContentWrapper,
    NoDataImage,
    NoDataWrapper
} from './NoData.styles';

//components

//styles

interface Props {
    title?: string;
    description?: string;
}

export const NoData: ComponentType<Props> = pure(({ title, description }) => {
    return (
        <NoDataWrapper>
            <NoDataContainer title="">
                <NoDataContentWrapper>
                    <NoDataImage src="../../../../images/not_found.png" />
                    <ContentTitle>
                        {title ? title : 'KHÔNG CÓ DỮ LIỆU'}
                    </ContentTitle>
                    <ContentDescription>
                        {description
                            ? description
                            : 'Không tìm thấy dữ liệu liên quan. Bạn vui lòng thử lại sau.'}
                    </ContentDescription>
                </NoDataContentWrapper>
            </NoDataContainer>
        </NoDataWrapper>
    );
});
