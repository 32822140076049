export function dataURLtoFile(dataUrl: any, fileName: string): File {
    var arr = dataUrl[0].split(',');
    var mime = arr[0].match(/:(.*?);/);
    const type = mime != null ? mime[1] : undefined;

    var bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type });
}

export const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
