import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponseList } from '../models/APIResponse';
import { ListNetStation } from '../models/NetStation';
import {
    IGetListNetStationRequest,
    INetStation,
    INetStationFilter
} from '../types/INetStation';
import { NetStationService } from '../services/NetStationService';

class NetStationStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listNetStation: ListNetStation | null = null;
    @observable netStationFilter: INetStationFilter | null = null;

    @action updateNetStationFilter = (filter: INetStationFilter) => {
        runInAction(() => {
            this.netStationFilter = filter;
        });
    };

    @action getListNetStation = async (
        params: IGetListNetStationRequest,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listNetStation)
            runInAction(() => {
                this.listNetStation = new ListNetStation();
                this.listNetStation.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listNetStation.pageIndex += 1; //load more
        } else if (loadMore && pageIndex != 0) {
            this.listNetStation.pageIndex = pageIndex! - 1; //paging
        }

        if (this.listNetStation) {
            this.listNetStation.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listNetStation.pageIndex
                : this.listNetStation.pageSize * this.listNetStation.pageIndex;
        }

        //request api service
        const result = (await NetStationService.getInstance().getListNetStation(
            params
        )) as APIResponseList<Array<INetStation>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listNetStation) {
                    if (loadMore && !pageIndex)
                        this.listNetStation.list =
                            this.listNetStation.list?.concat(
                                ListNetStation.fromJson(result.data?.list!)
                            )!;
                    else
                        this.listNetStation.list = ListNetStation.fromJson(
                            result.data?.list!
                        );
                    this.listNetStation.totalRecord = result.totalRecord();
                    this.listNetStation.isLoading = false;
                }
            });
        } else if (this.listNetStation) this.listNetStation.isLoading = false;
        return result;
    };
}

export default NetStationStore;
