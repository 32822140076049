import { ComponentType, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import pure from 'recompose/pure';
import _ from 'lodash';
import { useRootStore } from '../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { IGetTokenRequest } from '../../types/ILogin';
import { Spin, message } from 'antd';
import { AuthContainer, AuthLoadingWrapper, AuthWrapper } from './Auth.styles';
const queryString = require('query-string');

//components

//styles

interface Props {}

export const Auth: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { getToken } = store.authStore;
    const navigation = useNavigate();

    let paramsQuery = queryString.parse(window.location.search);
    if (_.isEmpty(paramsQuery) || _.isEmpty(paramsQuery.access_token))
        return <Navigate to="/login"></Navigate>;

    useEffect(() => {
        onGetToken();
    }, []);

    const [tokenInfo, setTokenInfo] = useState('');

    const onGetToken = async () => {
        const params: IGetTokenRequest = {
            data: {
                accessToken: paramsQuery.access_token
            }
        };
        const result = await getToken(params);
        if (result && result.isSuccess()) {
            console.log(result.data);
            setTokenInfo(JSON.stringify(result.data));
            navigation('/net-station');
        } else {
            //message.error('Đăng nhập không thành công !');
            navigation('/login');
        }
    };

    return (
        <AuthWrapper>
            <AuthContainer>
                <AuthLoadingWrapper>
                    <Spin />
                </AuthLoadingWrapper>
            </AuthContainer>
        </AuthWrapper>
    );
});
