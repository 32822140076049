import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponse, APIResponseList } from '../models/APIResponse';
import { ListProduct } from '../models/Product';
import { IGetProductListRequest, IProduct } from '../types/IProduct';
import { FeedbackService } from '../services/FeedbackService';
import { IAddGameRequest, IFeedbackProductRequest } from '../types/IFeedback';
import { IResponseBase } from '../types/ITypeBase';

class FeedbackStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listProduct: ListProduct | null = null;

    @action getListProduct = async (
        params: IGetProductListRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listProduct)
            runInAction(() => {
                this.listProduct = new ListProduct();
            });
        else if (loadMore) {
            this.listProduct.pageIndex += 1;
            this.listProduct.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listProduct.pageIndex
                : this.listProduct.pageSize * this.listProduct.pageIndex;
        }

        //request api service
        const result = (await FeedbackService.getInstance().getListProduct(
            params
        )) as APIResponseList<Array<IProduct>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listProduct) {
                    this.listProduct.list = this.listProduct.list?.concat(
                        ListProduct.fromJson(result.data?.list!)
                    )!;
                    this.listProduct.totalRecord = result.totalRecord();
                    this.listProduct.isLoading = false;
                }
            });
        }
        return result;
    };

    @action feedbackProduct = async (params: IFeedbackProductRequest) => {
        const result = (await FeedbackService.getInstance().feedbackProduct(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action requestAddGame = async (params: IAddGameRequest) => {
        const result = (await FeedbackService.getInstance().requestAddGame(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };
}

export default FeedbackStore;
