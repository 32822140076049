import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import pure from 'recompose/pure';
import { FeedbackSchema } from '../../validation/FeedbackSchema';
import {
    IFeedbackProduct,
    IFeedbackProductRequest
} from '../../types/IFeedback';
import {
    ErrorText,
    FeedbackInfoContainer,
    FeedbackInfoWrapper,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    SendButton
} from './FeedbackInfo.styles';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import { Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetProductListRequest } from '../../types/IProduct';

//components

//styles

interface Props {}
const { Option } = Select;

export const FeedbackInfo: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listProduct, getListProduct, feedbackProduct } =
        store.feedbackStore;

    const {
        register,
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IFeedbackProduct>({
        resolver: yupResolver(FeedbackSchema),
        defaultValues: {}
    });

    useEffect(() => {
        if (!listProduct) onGetListProduct();
    }, []);

    const onGetListProduct = async () => {
        const params: IGetProductListRequest = {};
        const result = await getListProduct(params);
    };

    const onSubmit = async (data: IFeedbackProduct) => {
        console.log(data);
        const params: IFeedbackProductRequest = {
            data: {
                productId: data.product,
                content: data.content
            }
        };
        const result = await feedbackProduct(params);
        if (result && result.isSuccess()) {
            Modal.success({
                title: 'Góp ý thành công',
                content: 'Cám ơn bạn đã góp ý cho sản phẩm',
                centered: true
            });
            reset();
        } else {
            Modal.error({
                title: 'Góp ý không thành công',
                content:
                    'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi. Mã lỗi: ' +
                    result.responseCode(),
                centered: true
            });
        }
    };

    return (
        <FeedbackInfoWrapper>
            <FeedbackInfoContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper>
                        <LabelText>Sản phẩm góp ý</LabelText>
                        {listProduct?.hasData() ? (
                            <Controller
                                control={control}
                                name="product"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { invalid, error },
                                    formState
                                }) => (
                                    <Select
                                        value={value}
                                        defaultValue={'--Chọn sản phẩm--'}
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}>
                                        {listProduct?.list.map((item) => {
                                            return (
                                                <Option
                                                    key={item.productId}
                                                    value={item.productId}>
                                                    {item.productName}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                )}
                            />
                        ) : null}
                        {errors.product ? (
                            <ErrorText>{errors.product.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelText>Nội dung góp ý</LabelText>
                        <InputTextMultiline
                            placeholder=""
                            type="password"
                            {...register('content')}
                            isError={errors.content ? 'error' : ''}
                        />
                        {errors.content ? (
                            <ErrorText>{errors.content.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <SendButton
                        type="submit"
                        typeButton={APP_BUTTON_TYPE.PRIMARY}>
                        Gửi thông tin
                    </SendButton>
                </form>
            </FeedbackInfoContainer>
        </FeedbackInfoWrapper>
    );
});
