import styled from 'styled-components';
import { AppCard } from '../../../styles/App.styles';
import { AppButton } from '../../../styles/AppButton.styles';

export const NoDataWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const NoDataContainer = styled(AppCard)`
    .ant-card-body {
        padding: 20px;
    }
`;

export const NoDataContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const NoDataImage = styled.img`
    object-fit: cover;
    max-width: 150px;
`;

export const ContentTitle = styled.h2`
    font-weight: 700;
    color: #3a3b3d;
    font-size: 18px;
    margin-top: 20px;
`;

export const ContentDescription = styled.h2`
    font-weight: 500;
    color: #3a3b3d;
    font-size: 16px;
`;

export const VerifyButton = styled(AppButton)`
    margin-top: 20px;
    padding: 200px;
    height: 40px;
`;
