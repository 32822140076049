import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { UserLogin } from './UserLogin';
//styles
import {
    FuDrawer,
    MenuContainer,
    MenuItem,
    MenuLink
} from './MenuDrawer.styles';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../../../types/IGlobal';
import { observer } from 'mobx-react-lite';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const MenuDrawer: ComponentType<Props> = observer(
    ({ open, onClose }) => {
        const store = useRootStore();
        const { indexMainMenu, changeMainMenuIndex } = store.globalStore;

        const onChangeMainMenuIndex = (index: MAIN_MENU) => {
            onClose();
            changeMainMenuIndex(index);
        };

        return (
            <FuDrawer
                title=""
                placement="right"
                onClose={onClose}
                visible={open}>
                <UserLogin />
                <MenuContainer>
                    <MenuLink
                        onClick={() =>
                            onChangeMainMenuIndex(MAIN_MENU.FEEDBACK)
                        }
                        isActive={
                            indexMainMenu == MAIN_MENU.FEEDBACK ? 'active' : ''
                        }
                        to="/feedback">
                        <MenuItem
                            isActive={
                                indexMainMenu == MAIN_MENU.FEEDBACK
                                    ? 'active'
                                    : ''
                            }>
                            GÓP Ý SẢN PHẨM
                        </MenuItem>
                    </MenuLink>
                    <MenuLink
                        onClick={() =>
                            onChangeMainMenuIndex(MAIN_MENU.NET_STATION_INFO)
                        }
                        isActive={
                            indexMainMenu == MAIN_MENU.NET_STATION_INFO
                                ? 'active'
                                : ''
                        }
                        to="/net-station">
                        <MenuItem
                            isActive={
                                indexMainMenu == MAIN_MENU.NET_STATION_INFO
                                    ? 'active'
                                    : ''
                            }>
                            DANH SÁCH PHÒNG MÁY
                        </MenuItem>
                    </MenuLink>
                    <MenuLink
                        onClick={() => onChangeMainMenuIndex(MAIN_MENU.TICKET)}
                        isActive={
                            indexMainMenu == MAIN_MENU.TICKET ? 'active' : ''
                        }
                        to="/ticket">
                        <MenuItem
                            isActive={
                                indexMainMenu == MAIN_MENU.TICKET
                                    ? 'active'
                                    : ''
                            }>
                            QUẢN LÝ TICKET
                        </MenuItem>
                    </MenuLink>
                </MenuContainer>
            </FuDrawer>
        );
    }
);
