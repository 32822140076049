import { IProduct } from '../types/IProduct';
import { DataList } from './DataList';

export default class Product {
    public productId: string;
    public productName: string;

    constructor(productId: string, productName: string) {
        this.productId = productId;
        this.productName = productName;
    }

    static fromJson = (json: IProduct): Product => {
        return new Product(json.productId, json.productName);
    };
}

export class ListProduct extends DataList<Product> {
    static fromJson = (jsonArray: Array<IProduct>): Array<Product> => {
        if (!jsonArray) return [];
        let data = new Array<Product>();
        jsonArray.forEach((item) => {
            data.push(Product.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
