import { ComponentType, useEffect, useState } from 'react';
import { pure } from 'recompose';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

//components

//import styles
import {
    HeaderWrapper,
    HeaderContainer,
    AppLogoContainer,
    AppLogoImage,
    MenuContainer,
    MenuLink,
    MenuItem
} from './HeaderWeb.styles';

import { useRootStore } from '../../../../providers/RootStoreProvider';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import { MAIN_MENU } from '../../../../types/IGlobal';
import { UserLogin } from './UserLogin';

export const HeaderWeb: ComponentType = pure(
    observer(({}) => {
        const store = useRootStore();
        const { currentUser } = store.authStore;
        const { indexMainMenu, changeMainMenuIndex } = store.globalStore;

        useEffect(() => {}, []);

        const onChangeMainMenuIndex = (index: MAIN_MENU) => {
            changeMainMenuIndex(index);
        };

        return (
            <HeaderWrapper>
                <HeaderContainer>
                    <AppLogoContainer title="1.0.3">
                        <AppLogoImage src="../../../images/app_logo.png"></AppLogoImage>
                    </AppLogoContainer>
                    <MenuContainer>
                        <MenuLink
                            onClick={() =>
                                onChangeMainMenuIndex(MAIN_MENU.FEEDBACK)
                            }
                            isActive={
                                indexMainMenu == MAIN_MENU.FEEDBACK
                                    ? 'active'
                                    : ''
                            }
                            to="/feedback">
                            <MenuItem
                                isActive={
                                    indexMainMenu == MAIN_MENU.FEEDBACK
                                        ? 'active'
                                        : ''
                                }>
                                GÓP Ý SẢN PHẨM
                            </MenuItem>
                        </MenuLink>
                        <MenuLink
                            onClick={() =>
                                onChangeMainMenuIndex(
                                    MAIN_MENU.NET_STATION_INFO
                                )
                            }
                            isActive={
                                indexMainMenu == MAIN_MENU.NET_STATION_INFO
                                    ? 'active'
                                    : ''
                            }
                            to="/net-station">
                            <MenuItem
                                isActive={
                                    indexMainMenu == MAIN_MENU.NET_STATION_INFO
                                        ? 'active'
                                        : ''
                                }>
                                DANH SÁCH PHÒNG MÁY
                            </MenuItem>
                        </MenuLink>
                        <MenuLink
                            onClick={() =>
                                onChangeMainMenuIndex(MAIN_MENU.TICKET)
                            }
                            isActive={
                                indexMainMenu == MAIN_MENU.TICKET
                                    ? 'active'
                                    : ''
                            }
                            to="/ticket">
                            <MenuItem
                                isActive={
                                    indexMainMenu == MAIN_MENU.TICKET
                                        ? 'active'
                                        : ''
                                }>
                                QUẢN LÝ TICKET
                            </MenuItem>
                        </MenuLink>

                        <MenuLink
                            onClick={() =>
                                onChangeMainMenuIndex(MAIN_MENU.FFOOD_TICKET)
                            }
                            isActive={
                                indexMainMenu == MAIN_MENU.FFOOD_TICKET
                                    ? 'active'
                                    : ''
                            }
                            to="/ffood-ticket">
                            <MenuItem
                                isActive={
                                    indexMainMenu == MAIN_MENU.FFOOD_TICKET
                                        ? 'active'
                                        : ''
                                }>
                                FFOOD TICKET
                            </MenuItem>
                        </MenuLink>

                        <UserLogin />
                    </MenuContainer>
                </HeaderContainer>
            </HeaderWrapper>
        );
    })
);
