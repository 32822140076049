import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IAddGameRequest, IFeedbackProductRequest } from '../types/IFeedback';
import { IGetListNetStationRequest } from '../types/INetStation';
import { IGetProductListRequest } from '../types/IProduct';
import BaseAuthService from './BaseAuthService';

export class FeedbackService extends BaseAuthService {
    private static instance: FeedbackService;

    public static getInstance(): FeedbackService {
        if (!FeedbackService.instance) {
            FeedbackService.instance = new FeedbackService();
        }
        return FeedbackService.instance;
    }

    getListProduct = async (params: IGetProductListRequest) => {
        try {
            const response = await this.postData(API.GLOBAL.PRODUCT, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    feedbackProduct = async (params: IFeedbackProductRequest) => {
        try {
            const response = await this.postData(API.USER.FEEDBACK, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    requestAddGame = async (params: IAddGameRequest) => {
        try {
            const response = await this.postData(API.USER.ADD_GAME, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
