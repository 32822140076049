import styled from 'styled-components';

export const NetStationTitle = styled.h1`
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
`;

export const NetStationListWrapper = styled.div`
    border: solid 1px #cfd4d1;
    margin-top: 30px;
`;

export const LoadingWrapper = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LoadingText = styled.p`
    margin: 0;
    margin-top: 20px;
    font-size: 16px;
`;

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
`;
