import styled from 'styled-components';
import { device } from '../configs/MediaQuery';
import { Card } from 'antd';

export const BackToTop = styled.div`
    background-image: url('../../../images/back_to_top.png');
    background-position: center;
    background-size: cover;
    width: 60px;
    height: 60px;
`;

export const AppTitle = styled.h1`
    font-weight: 700;
    color: #fff;
    @media ${device.mobileS} {
        font-size: 28px;
        margin: 0;
        //margin-left: 20px;
    }
    @media ${device.laptop} {
        margin: 0;
        font-size: 32px;
    }
`;

export const AppLineDivider = styled.div`
    width: 100%;
    background-color: #707070;

    @media ${device.mobileS} {
        height: 0px;
    }
    @media ${device.laptop} {
        height: 1px;
        margin-bottom: 40px;
    }
`;

export const AppDescription = styled.p`
    font-weight: 400;
    color: #fff;

    @media ${device.mobileS} {
        font-size: 14px;
        margin-bottom: 0px;
    }
    @media ${device.laptop} {
        margin: 0;
        font-size: 16px;
    }
`;

export const AppContentWrapper = styled.div`
    width: 100%;
    min-height: 600px;
    background-color: #fff;
`;

export const AppContentContainer = styled.div`
    padding-bottom: 200px;
    padding-top: 20px;
    @media ${device.mobileS} {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ${device.laptop} {
        width: 1100px;
        margin: 0 auto;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const AppCard = styled(Card)`
    background-color: #fff;
    border-color: #fff;
    color: #3a3b3d;

    .ant-card-head {
        border-bottom: 3px solid #3a3b3d;
        font-weight: 700;
        font-size: 20px;
        color: #54b4a4;
    }

    ant-card-body {
        padding: 0;
        margin: 0;
    }
`;

interface AppSizedBoxProps {
    height: boolean;
}
export const AppSizedBox = styled.div.attrs((props: AppSizedBoxProps) => ({
    height: props.height
}))``;
