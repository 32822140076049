import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';

//components
import { Tag } from 'antd';

//styles
import {
    NetStationWrapper,
    NetStationContainer,
    TitleText,
    DescriptionText,
    RequestSupportButton,
    NetStationInfoWrapper,
    RegisterDurationWrapper,
    RegisterDurationText,
    TitleWrapper,
    ButtonWrapper,
    ViewDetailButton
} from './NetStationItem.styles';
import { NetStation } from '../../../models/NetStation';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import moment from 'moment';
import { BarsOutlined } from '@ant-design/icons';

interface Props {
    data: NetStation;
    addTicket: (data: NetStation) => void;
}

export const NetStationItem: ComponentType<Props> = pure(
    ({ data, addTicket }) => {
        useEffect(() => {
            getDayExpired();
        }, []);

        const onAddTicket = () => {
            addTicket(data);
        };

        const getDayExpired = () => {
            const dayExpired = moment(data?.toDate);
            const dayNow = moment(new Date());
            const numDays = dayExpired.diff(dayNow, 'days');

            if (numDays <= 0) return <Tag color="error">Hết hạn</Tag>;
            else if (numDays <= 7)
                return <Tag color="warning">Còn {numDays} ngày là hết hạn</Tag>;
            else return <Tag color="success">Đang hoạt động</Tag>;
        };

        const getDurationRegisters = () => {
            const format = 'DD/MM/YYYY';
            return (
                moment(data?.fromDate).format(format) +
                ' - ' +
                moment(data?.toDate).format(format)
            );
        };

        return (
            <NetStationWrapper>
                <NetStationContainer>
                    <NetStationInfoWrapper>
                        <TitleWrapper>
                            <TitleText>{data?.netName}</TitleText>
                            {getDayExpired()}
                        </TitleWrapper>
                        <DescriptionText>
                            {data.owner} - {data.phone}
                        </DescriptionText>
                        <DescriptionText>
                            ID: <strong>{data.netId} - </strong>
                        </DescriptionText>
                        <DescriptionText>
                            MC:
                            <strong>{data.netCode}</strong>
                        </DescriptionText>
                        <DescriptionText>
                            Địa chỉ:
                            <strong>{data?.address}</strong>
                        </DescriptionText>
                        <RegisterDurationWrapper>
                            <DescriptionText>
                                Thời gian đăng ký:
                            </DescriptionText>
                            <RegisterDurationText>
                                {getDurationRegisters()}
                            </RegisterDurationText>
                        </RegisterDurationWrapper>

                        <RegisterDurationWrapper></RegisterDurationWrapper>
                    </NetStationInfoWrapper>
                    <ButtonWrapper>
                        <RequestSupportButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            onClick={onAddTicket}>
                            Gửi yêu cầu hỗ trợ
                        </RequestSupportButton>
                        {/* <ViewDetailButton typeButton={APP_BUTTON_TYPE.BORDER}>
                            <BarsOutlined />
                        </ViewDetailButton> */}
                    </ButtonWrapper>
                </NetStationContainer>
            </NetStationWrapper>
        );
    }
);
