import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//components

//styles
import {
    AcceptButton,
    ButtonWrapper,
    CancelButton,
    CloseButton,
    CommentButton,
    CommentText,
    CopyWrapper,
    DescriptionText,
    NetStationInfoWrapper,
    RatingButton,
    RatingContentWrapper,
    RatingStart,
    RatingWrapper,
    TicketInfoContainer,
    TicketInfoWrapper,
    TicketItemContainer,
    TicketItemWrapper,
    TitleText,
    TitleTextL2,
    TitleWrapper
} from './TicketItem.styles';
import { Tag, Collapse, message } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { CommentOutlined, CopyOutlined } from '@ant-design/icons';
import Ticket from '../../../models/Ticket';
import {
    ITicketDialogData,
    TICKET_ACTION,
    TICKET_STATUS
} from '../../../types/ITicket';
import { observer } from 'mobx-react-lite';

interface Props {
    data: Ticket;
    openCommentDialog: (ticket: Ticket) => void;
    openRatingDialog: (ticket: Ticket) => void;
    actionTicket: (ticket: Ticket, dialogData: ITicketDialogData) => void;
}

const { Panel } = Collapse;

export const TicketItem: ComponentType<Props> = observer(
    ({ data, openCommentDialog, openRatingDialog, actionTicket }) => {
        const getStatusTicket = () => {
            let statusTicket;
            switch (data.status) {
                case TICKET_STATUS.NEW:
                    return <Tag color="magenta">{data.statusName}</Tag>;
                case TICKET_STATUS.COMPLETED:
                    return <Tag color="success">{data.statusName}</Tag>;
                case TICKET_STATUS.CANCEL:
                    return <Tag color="error">{data.statusName}</Tag>;
                case TICKET_STATUS.PROCESSING:
                    return <Tag color="orange">{data.statusName}</Tag>;
            }
        };

        const onOpenCommentDialog = () => {
            openCommentDialog(data);
        };

        const onOpenRatingDialog = () => {
            openRatingDialog(data);
        };

        const onActionTicket = (action: TICKET_ACTION) => {
            let dialogData: ITicketDialogData;
            if (action === TICKET_ACTION.ACCEPT)
                dialogData = {
                    action: action,
                    title: 'Đồng ý nhận case',
                    description:
                        'Nhập mô tả vào bên dưới và nhấn Xác nhận để tiếp nhận case hỗ trợ. <br /> <strong>Lưu ý:</strong> Sau khi nhấn xác nhận, bạn vui lòng liên hệ khách hàng để hỗ trợ hoàn tất'
                };
            else if (action === TICKET_ACTION.CANCEL)
                dialogData = {
                    action: action,
                    title: 'Từ chối nhận case',
                    description:
                        'Nhập mô tả vào bên dưới và nhấn Xác nhận để chuyển case này về lại cho Sale leader xử lý tiếp.'
                };
            else if (action === TICKET_ACTION.CLOSE)
                dialogData = {
                    action: action,
                    title: 'Phản hồi hỗ trợ',
                    description:
                        'Nếu vấn đề đã được xử lý hoặc không còn nhu cầu hỗ trợ, nhấn Xác nhận để đóng case.'
                };
            actionTicket(data, dialogData!);
        };

        return (
            <TicketItemWrapper>
                <TicketItemContainer>
                    <TicketInfoContainer>
                        <TicketInfoWrapper>
                            <TitleWrapper>
                                <TitleText>
                                    Mã yêu cầu: #{data.ticketId}
                                </TitleText>
                                {getStatusTicket()}
                            </TitleWrapper>
                            {data.rating > 0 ? (
                                <TitleWrapper>
                                    <DescriptionText>
                                        Đánh giá :&nbsp;
                                    </DescriptionText>
                                    <RatingWrapper>
                                        <RatingStart
                                            value={data.rating}></RatingStart>

                                        <RatingContentWrapper>
                                            <TitleTextL2>
                                                ({data.ratingBy}
                                            </TitleTextL2>
                                            <CommentText>
                                                {' '}
                                                - {data.ratingContent})
                                            </CommentText>
                                        </RatingContentWrapper>
                                    </RatingWrapper>
                                </TitleWrapper>
                            ) : null}
                            <TitleWrapper>
                                <DescriptionText>
                                    Ngày gửi yêu cầu :&nbsp;
                                </DescriptionText>
                                <TitleTextL2>{data.createDate}</TitleTextL2>
                            </TitleWrapper>
                            <TitleWrapper>
                                <DescriptionText>
                                    Nội dung yêu cầu :&nbsp;
                                </DescriptionText>
                                <TitleTextL2>{data.contentRequest}</TitleTextL2>
                            </TitleWrapper>
                            <TitleWrapper>
                                <DescriptionText>
                                    Tài khoản UltraView :&nbsp;
                                </DescriptionText>
                                <TitleTextL2>
                                    {data.ultraAccount} / {data.ultraPass}
                                </TitleTextL2>
                            </TitleWrapper>
                        </TicketInfoWrapper>
                        <ButtonWrapper>
                            {data.status == TICKET_STATUS.NEW ? (
                                <>
                                    <CancelButton
                                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                                        onClick={() =>
                                            onActionTicket(TICKET_ACTION.CANCEL)
                                        }>
                                        Từ chối
                                    </CancelButton>
                                    <AcceptButton
                                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                                        onClick={() =>
                                            onActionTicket(TICKET_ACTION.ACCEPT)
                                        }>
                                        Nhận case
                                    </AcceptButton>
                                </>
                            ) : null}
                            {data.status == TICKET_STATUS.PROCESSING ? (
                                <>
                                    <CancelButton
                                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                                        onClick={() =>
                                            onActionTicket(TICKET_ACTION.CANCEL)
                                        }>
                                        Từ chối
                                    </CancelButton>
                                    <CloseButton
                                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                                        onClick={() =>
                                            onActionTicket(TICKET_ACTION.CLOSE)
                                        }>
                                        Đóng case
                                    </CloseButton>
                                </>
                            ) : null}
                            {data.isRanking ? (
                                <RatingButton
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}
                                    onClick={() => onOpenRatingDialog()}>
                                    Đánh giá hỗ trợ
                                </RatingButton>
                            ) : null}
                            {data.isComment ? (
                                <CommentButton
                                    typeButton={APP_BUTTON_TYPE.BORDER}
                                    onClick={() => onOpenCommentDialog()}>
                                    <CommentOutlined
                                        style={{
                                            fontSize: 25,
                                            color: '#54b4a4'
                                        }}
                                    />
                                </CommentButton>
                            ) : null}
                        </ButtonWrapper>
                    </TicketInfoContainer>

                    <NetStationInfoWrapper>
                        <Panel header="Thông tin phòng máy" key={1}>
                            <TitleWrapper>
                                <DescriptionText>
                                    Tên phòng máy :&nbsp;
                                </DescriptionText>
                                <TitleTextL2>{data.netName}</TitleTextL2>
                            </TitleWrapper>
                            <TitleWrapper>
                                <DescriptionText>
                                    Mã số phòng máy :&nbsp;
                                </DescriptionText>

                                <CopyWrapper>
                                    <TitleTextL2>{data.netCode}</TitleTextL2>

                                    <CopyToClipboard
                                        text={data.netCode}
                                        onCopy={() =>
                                            message.success(
                                                'Sao chép mã phòng máy thành công !'
                                            )
                                        }>
                                        <CopyOutlined
                                            style={{
                                                fontSize: 20,
                                                color: '#54b4a4',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </CopyToClipboard>
                                </CopyWrapper>
                            </TitleWrapper>
                            <TitleWrapper>
                                <DescriptionText>
                                    Chủ phòng máy :&nbsp;
                                </DescriptionText>
                                <CopyWrapper>
                                    <TitleTextL2>{data.owner}</TitleTextL2>
                                    <TitleTextL2>- {data.phone}</TitleTextL2>

                                    <CopyToClipboard
                                        text={data.phone}
                                        onCopy={() =>
                                            message.success(
                                                'Sao chép điện thoại phòng máy thành công !'
                                            )
                                        }>
                                        <CopyOutlined
                                            style={{
                                                fontSize: 20,
                                                color: '#54b4a4',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </CopyToClipboard>
                                </CopyWrapper>
                            </TitleWrapper>
                            <TitleWrapper>
                                <DescriptionText>
                                    Địa chỉ :&nbsp;
                                </DescriptionText>
                                <TitleTextL2>{data.address}</TitleTextL2>
                            </TitleWrapper>
                        </Panel>
                    </NetStationInfoWrapper>
                </TicketItemContainer>
            </TicketItemWrapper>
        );
    }
);
