import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    CommentModal,
    DialogTitleContainer,
    DialogTitleText,
    TicketCommentWrapper,
    CommentListWrapper,
    CommentInputWrapper,
    SendComment,
    CommentInputText
} from './TicketCommentFFoodDialog.styles';
import { CommentItem } from './CommentItem/CommentItem';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import {
    IAddTicketCommentRequest,
    IGetListTicketCommentsRequest,
    ITicketComment,
    TICKET_ACTION
} from '../../../types/ITicket';
import Ticket from '../../../models/Ticket';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommentTicketSchema } from '../../../validation/CommentTicketSchema';
import TicketComment from '../../../models/TicketComment';
import { Modal } from 'antd';

interface Props {
    ticket: Ticket;
    visible: boolean;
    onClose: () => void;
    onCommentSuccess?: () => void;
}

export const TicketCommentFFoodDialog: ComponentType<Props> = observer(
    ({ visible, ticket, onClose, onCommentSuccess }) => {
        const store = useRootStore();
        const {
            commentTicketFFood,
            listTicketComment,
            addCommentToList,
            getListTicketCommentFFood
        } = store.ticketStore;

        const {
            register,
            handleSubmit,
            control,
            reset,
            resetField,
            formState: { errors }
        } = useForm<ITicketComment>({
            resolver: yupResolver(CommentTicketSchema),
            defaultValues: {}
        });

        const [allowComment, setAllowComment] = useState(true);

        useEffect(() => {
            onGetListTicketComment();
        }, []);

        const onGetListTicketComment = async () => {
            const params: IGetListTicketCommentsRequest = {
                data: {
                    ticketId: ticket.ticketId
                }
            };
            const result = await getListTicketCommentFFood(params);
        };

        const onSubmitComment = async (data: ITicketComment) => {
            console.log(data);
            const params: IAddTicketCommentRequest = {
                data: {
                    ticketId: ticket.ticketId,
                    commentContent: data.commentContent,
                    status: TICKET_ACTION.COMMENT
                }
            };
            const result: any = await commentTicketFFood(params);
            if (result && result.isSuccess()) {
                reset();
                //onGetListTicketComment(); //refresh the list
                const newComment = new TicketComment(
                    result.data?.ticketId,
                    result.data.commentId,
                    result.data.userName,
                    result.data.userName,
                    result.data.fullName,
                    result.data.commentDate,
                    result.data.commentContent
                );
                const isComment = result.data.isComment;
                setAllowComment(isComment);
                ticket.updateCommentStatus(isComment);
                addCommentToList(newComment);
            } else {
                Modal.error({
                    title: 'Gửi thảo luận không thành công',
                    content:
                        'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                    centered: true
                });
            }
        };

        return (
            <TicketCommentWrapper>
                <CommentModal
                    centered
                    onCancel={onClose}
                    visible={visible}
                    footer={null}>
                    <DialogTitleContainer>
                        <DialogTitleText>Phản hồi hỗ trợ</DialogTitleText>
                    </DialogTitleContainer>
                    <CommentListWrapper>
                        {listTicketComment &&
                            listTicketComment.list.map((item) => {
                                return (
                                    <CommentItem
                                        data={item}
                                        key={item.commentId}
                                    />
                                );
                            })}
                    </CommentListWrapper>

                    <form onSubmit={handleSubmit(onSubmitComment)}>
                        {allowComment ? (
                            <CommentInputWrapper>
                                <CommentInputText
                                    {...register('commentContent')}
                                    isError={
                                        errors.commentContent ? 'error' : ''
                                    }
                                />
                                <SendComment
                                    type="submit"
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                    Gửi
                                </SendComment>
                            </CommentInputWrapper>
                        ) : null}
                    </form>
                </CommentModal>
            </TicketCommentWrapper>
        );
    }
);
