import { INetStation, NET_STATION_STATUS } from '../types/INetStation';
import { DataList } from './DataList';

export class NetStation {
    public netId: string;
    public netName: string;
    public netCode: string;
    public owner: string;
    public phone: string;
    public address: string;
    public fromDate: string;
    public toDate: string;
    public status: NET_STATION_STATUS;
    public ts: string;
    public token: string;
    public numDays: number;

    constructor(
        netId: string,
        netName: string,
        netCode: string,
        owner: string,
        phone: string,
        address: string,
        fromDate: string,
        toDate: string,
        status: NET_STATION_STATUS,
        ts: string,
        token: string,
        numDays: number
    ) {
        this.netId = netId;
        this.netName = netName;
        this.netCode = netCode;
        this.owner = owner;
        this.phone = phone;
        this.address = address;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.status = status;
        this.ts = ts;
        this.token = token;
        this.numDays = numDays;
    }

    static fromJson = (json: INetStation): NetStation => {
        return new NetStation(
            json.netId,
            json.netName,
            json.netCode,
            json.owner,
            json.phone,
            json.address,
            json.fromDate,
            json.toDate,
            json.status,
            json.ts,
            json.token,
            json.numDays
        );
    };
}

export class ListNetStation extends DataList<NetStation> {
    static fromJson = (jsonArray: Array<INetStation>): Array<NetStation> => {
        if (!jsonArray) return [];
        let data = new Array<NetStation>();
        jsonArray.forEach((item) => {
            data.push(NetStation.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
