import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
//components

//styles
import {
    AddTicketDialogWrapper,
    AddTicketModal,
    DeleteUpload,
    DialogTitleContainer,
    DialogTitleText,
    ErrorText,
    ImageUpload,
    ImageUploadWrapper,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    NetStationSelect,
    SendButton,
    UploadButton
} from './AddTicketDialog.styles';
import { NetStation } from '../../../models/NetStation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddTicketSchema } from '../../../validation/AddTicketSchema';
import {
    IAddTicketRequest,
    ITicketBase,
    ITicketFilter,
    TICKET_STATUS
} from '../../../types/ITicket';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { convertBase64 } from '../../../utils/FileUtils';
import { useNavigate } from 'react-router-dom';

interface Props {
    netStation?: NetStation;
    visible: boolean;
    onClose: () => void;
}

const { Option } = Select;

export const AddTicketDialog: ComponentType<Props> = observer(
    ({ netStation, visible, onClose }) => {
        const store = useRootStore();
        const { listNetStation } = store.netStationStore;
        const { addTicket, updateTicketFilter } = store.ticketStore;
        const { changeDialogAddTicketActive } = store.globalStore;

        const [imageBase64, setImageBase64] = useState('');
        const [isCloseTicket, setIsCloseTicket] = useState(false);

        const {
            register,
            handleSubmit,
            control,
            reset,
            resetField,
            formState: { errors }
        } = useForm<ITicketBase>({
            resolver: yupResolver(AddTicketSchema),
            defaultValues: {
                netId: netStation != null ? netStation.netId : ''
            }
        });

        const navigation = useNavigate();

        const onCloseDialog = () => {
            changeDialogAddTicketActive(false);
            onClose();
        };

        const onCloseTicket = (ticketId: any) => {
            onCloseDialog();
            const filter: ITicketFilter = {
                phone: '',
                status: TICKET_STATUS.ALL,
                ticketId: ticketId
            };
            reset();
            updateTicketFilter(filter);
            navigation('/ticket');
        };

        const onSubmit = async (data: ITicketBase) => {
            const params: IAddTicketRequest = {
                data: {
                    netId: data.netId,
                    ultraAccount: data.ultraAccount,
                    ultraPass: data.ultraPass,
                    contentRequest: data.contentRequest,
                    image: imageBase64,
                    ts: netStation != null ? netStation.ts : '',
                    token: netStation != null ? netStation.token : ''
                }
            };
            const result: any = await addTicket(params);
            if (result && result.isSuccess()) {
                reset();
                Modal.success({
                    title: 'Gửi yêu cầu thành công',
                    content:
                        'Chúng tôi đã tiếp nhận yêu cầu. Vui lòng chờ bộ phận hỗ trợ liên hệ lại (7:30-22:00)',
                    centered: true
                });
                onCloseDialog();
                return;
            } else {
                if (result.err == -101) {
                    setIsCloseTicket(true);
                }

                Modal.confirm({
                    okText: 'OK',
                    cancelText: `Đi đến yêu cầu [#${result.data.ticketId}]`,
                    okCancel: result.err == -101 ? true : false,
                    onCancel: () => onCloseTicket(result.data.ticketId),
                    title: 'Gửi yêu cầu không thành công',
                    content:
                        result.getDescription() &&
                        result.getDescription().length > 0
                            ? result.getDescription()
                            : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                    centered: true
                });
            }
        };

        const onFileImageChange = async (e: any) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const size = file.size / 1024 / 1024;

                if (size > 10) {
                    Modal.error({
                        title: 'Dung lượng hình quá lớn',
                        content:
                            'Dung lượng hình cho phép tối đa 10MB. Bạn vui lòng giảm dung lượng hình ảnh',
                        centered: true
                    });
                    e.target.value = null;
                    return;
                }

                convertBase64(file).then((data) => {
                    //console.log(data);
                    setImageBase64(data as string);
                    e.target.value = null;
                });
            }
        };

        const onDeleteUpload = () => {
            setImageBase64('');
        };

        return (
            <AddTicketDialogWrapper>
                <AddTicketModal
                    centered
                    onCancel={onCloseDialog}
                    visible={visible}
                    footer={null}>
                    <DialogTitleContainer>
                        <DialogTitleText>Yêu cầu hỗ trợ</DialogTitleText>
                    </DialogTitleContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputWrapper>
                            <LabelText>FUID/MC phòng máy cần hỗ trợ</LabelText>

                            <InputText
                                placeholder=""
                                type="text"
                                readOnly={netStation != null}
                                {...register('netId')}
                                isError={errors.netId ? 'error' : ''}
                            />
                            {errors.netId ? (
                                <ErrorText>{errors.netId.message}</ErrorText>
                            ) : null}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Tài khoản UltraView</LabelText>
                            <InputText
                                placeholder=""
                                type="text"
                                {...register('ultraAccount')}
                                isError={errors.ultraAccount ? 'error' : ''}
                            />
                            {errors.ultraAccount ? (
                                <ErrorText>
                                    {errors.ultraAccount.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Mật khẩu UltraView</LabelText>
                            <InputText
                                placeholder=""
                                type="text"
                                {...register('ultraPass')}
                                isError={errors.ultraPass ? 'error' : ''}
                            />
                            {errors.ultraPass ? (
                                <ErrorText>
                                    {errors.ultraPass.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Hình ảnh (nếu có)</LabelText>

                            {imageBase64 ? (
                                <ImageUploadWrapper>
                                    <ImageUpload src={imageBase64} />
                                    <DeleteUpload
                                        onClick={() => onDeleteUpload()}>
                                        x
                                    </DeleteUpload>
                                </ImageUploadWrapper>
                            ) : (
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept="image/png, image/gif, image/jpeg"
                                        style={{ display: 'none' }}
                                        onChange={onFileImageChange}
                                    />

                                    <UploadButton>Chọn hình ảnh</UploadButton>
                                </label>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Nội dung yêu cầu hỗ trợ</LabelText>
                            <InputTextMultiline
                                placeholder=""
                                type="text"
                                {...register('contentRequest')}
                                isError={errors.contentRequest ? 'error' : ''}
                            />
                            {errors.contentRequest ? (
                                <ErrorText>
                                    {errors.contentRequest.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>
                        <SendButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            type="submit">
                            Gửi yêu cầu
                        </SendButton>
                    </form>
                </AddTicketModal>
            </AddTicketDialogWrapper>
        );
    }
);
