import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { useNavigate } from 'react-router-dom';
//components

//styles
import {
    AvatarImage,
    AvatarWrapper,
    LoginButton,
    LogoutButton,
    UserLoginContainer,
    UserLoginWrapper
} from './UserLogin.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';

interface Props {}

export const UserLogin: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser, logout } = store.authStore;

    const navigation = useNavigate();

    const renderContent = () => {
        if (currentUser?.isLogin()) {
            return <LoginView />;
        }
    };

    const onLogout = () => {
        logout();
        navigation('/login');
    };

    const LoginView = () => {
        return (
            <UserLoginContainer>
                <AvatarWrapper>
                    <AvatarImage
                        onError={() => alert('error')}
                        src={currentUser?.avatar}
                        placeholder={true}
                        preview={false}
                        fallback="../../../../images/default-avatar.png"></AvatarImage>
                </AvatarWrapper>
                <LogoutButton
                    typeButton={APP_BUTTON_TYPE.PRIMARY}
                    onClick={() => onLogout()}>
                    Đăng xuất
                </LogoutButton>
            </UserLoginContainer>
        );
    };

    return <UserLoginWrapper>{renderContent()}</UserLoginWrapper>;
});
