import { LOCAL_KEY } from './../configs/AppKey';
import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { FEEDBACK_SCREEN } from '../helpers/Screens';
import { MAIN_MENU } from '../types/IGlobal';

class GlobalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable language: string | null = null;
    @observable indexMainMenu: MAIN_MENU | null = null;
    @observable isDialogAddTicketActive: boolean = false;

    @observable feedbackScreen: FEEDBACK_SCREEN = FEEDBACK_SCREEN.FEEDBACK_INFO;

    @action changeFeedbackScreen = (screen: FEEDBACK_SCREEN) => {
        runInAction(() => {
            this.feedbackScreen = screen;
        });
    };

    @action changeDialogAddTicketActive = (val: boolean) => {
        runInAction(() => {
            this.isDialogAddTicketActive = val;
        });
    };

    @action
    changeLanguage = async (language: string) => {
        runInAction(() => {
            this.language = language;
        });
        localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
    };

    @action changeMainMenuIndex = async (index: MAIN_MENU) => {
        runInAction(() => {
            this.indexMainMenu = index;
        });
        //localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
    };
}
export default GlobalStore;
