import { ComponentType, useState } from 'react';

//components

//styles
import {
    CommentModal,
    DialogTitleContainer,
    DialogTitleText,
    TicketCommentWrapper,
    CommentInputWrapper,
    SendComment,
    CommentInputText
} from './TicketCloseFFoodDialog.styles';

import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';
import {
    IAddTicketCommentRequest,
    ITicketComment,
    ITicketDialogData,
    TICKET_ACTION
} from '../../../types/ITicket';
import Ticket from '../../../models/Ticket';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommentTicketSchema } from '../../../validation/CommentTicketSchema';
import { Modal } from 'antd';

interface Props {
    ticket: Ticket;
    visible: boolean;
    dialogData: ITicketDialogData;
    onClose: () => void;
    onCloseTicketSuccess?: () => void;
}

export const TicketCloseFFoodDialog: ComponentType<Props> = observer(
    ({ visible, ticket, dialogData, onClose, onCloseTicketSuccess }) => {
        const store = useRootStore();
        const { commentTicketFFood } = store.ticketStore;

        const {
            register,
            handleSubmit,
            control,
            reset,
            resetField,
            formState: { errors }
        } = useForm<ITicketComment>({
            //resolver: yupResolver(CommentTicketSchema),
            defaultValues: {}
        });

        const onSubmitComment = async (data: ITicketComment) => {
            console.log(data);
            const params: IAddTicketCommentRequest = {
                data: {
                    ticketId: ticket.ticketId,
                    commentContent: data.commentContent,
                    status: dialogData.action
                }
            };
            const result = await commentTicketFFood(params);
            if (result && result.isSuccess()) {
                ticket.updateActionTicket(dialogData.action);
                onClose();
                reset();
            } else {
                Modal.error({
                    title:
                        result.getTitle != null
                            ? result.getTitle.toString()
                            : 'Thao tác không thành công',
                    content:
                        result.getDescription().length > 0
                            ? result.getDescription()
                            : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                    centered: true
                });
            }
        };

        return (
            <TicketCommentWrapper>
                <CommentModal
                    centered
                    onCancel={onClose}
                    visible={visible}
                    footer={null}>
                    <DialogTitleContainer>
                        <DialogTitleText>
                            {dialogData.title
                                ? dialogData.title
                                : 'Phản hồi hỗ trợ'}
                        </DialogTitleText>
                    </DialogTitleContainer>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: dialogData.description
                                ? dialogData.description
                                : 'Nếu vấn đề đã được xử lý hoặc không còn nhu cầu hỗ trợ, nhấn Xác nhận để đóng case'
                        }}></p>
                    <form onSubmit={handleSubmit(onSubmitComment)}>
                        <CommentInputWrapper>
                            <CommentInputText
                                {...register('commentContent')}
                                isError={errors.commentContent ? 'error' : ''}
                            />
                            <SendComment
                                type="submit"
                                typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                Xác nhận
                            </SendComment>
                        </CommentInputWrapper>
                    </form>
                </CommentModal>
            </TicketCommentWrapper>
        );
    }
);
