import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
//components
import { Tabs, TabsProps } from 'antd';
import { FeedbackInfo } from './FeedbackInfo';
import { RequestGame } from './RequestGame';
import { TabBar } from '../../components/TabBar/TabBar';
import { ITabMenu, MAIN_MENU } from '../../types/IGlobal';
import { FEEDBACK_SCREEN } from '../../helpers/Screens';
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetListNetStationRequest } from '../../types/INetStation';
//styles

interface Props {}

const tabs: ITabMenu[] = [
    {
        id: 0,
        title: `Góp ý sản phẩm`
    },
    {
        id: 1,
        title: `Yêu cầu thêm Game`
    }
];

export const FeedbackView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { feedbackScreen, changeFeedbackScreen, changeMainMenuIndex } =
        store.globalStore;
    const { listNetStation, getListNetStation } = store.netStationStore;

    useEffect(() => {
        changeMainMenuIndex(MAIN_MENU.FEEDBACK);
        //onGetListNetStation();
    }, []);

    // const onGetListNetStation = async () => {
    //     const params: IGetListNetStationRequest = {
    //         data: {
    //             netName: '',
    //             phone: '',
    //             status: 0
    //         }
    //     };
    //     const result = await getListNetStation(params);
    // };

    const onChangeTab = (tab: number) => {
        changeFeedbackScreen(tab);
    };

    const renderContent = () => {
        if (feedbackScreen === FEEDBACK_SCREEN.FEEDBACK_INFO)
            return <FeedbackInfo />;
        else return <RequestGame />;
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TabBar
                    tabs={tabs}
                    defaultTab={feedbackScreen}
                    onChangeTab={onChangeTab}
                />
                {renderContent()}
            </AppContentContainer>
        </AppContentWrapper>
    );
});
