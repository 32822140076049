import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType } from 'react';
import { useForm } from 'react-hook-form';
import pure from 'recompose/pure';
import { FeedbackSchema } from '../../validation/FeedbackSchema';
import {
    IAddGameRequest,
    IFeedbackProduct,
    IRequestAddGame
} from '../../types/IFeedback';
import {
    ErrorText,
    RequestGameContainer,
    RequestGameWrapper,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    SendButton
} from './RequestGame.styles';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import { Modal, Select } from 'antd';
import { RequestAddGameSchema } from '../../validation/RequestAddGameSchema';
import { useRootStore } from '../../providers/RootStoreProvider';

//components

//styles

interface Props {}

export const RequestGame: ComponentType<Props> = pure(({}) => {
    const store = useRootStore();
    const { requestAddGame } = store.feedbackStore;
    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IRequestAddGame>({
        resolver: yupResolver(RequestAddGameSchema)
    });

    const onSubmit = async (data: IRequestAddGame) => {
        const params: IAddGameRequest = {
            data: {
                gameName: data.gameName,
                companyName: data.companyName,
                url: data.url,
                note: data.note
            }
        };
        const result = await requestAddGame(params);
        if (result && result.isSuccess()) {
            Modal.success({
                title: 'Yêu cầu thêm game thành công',
                content:
                    'Cám ơn bạn đã gửi yêu cầu. Chúng tôi sẽ xem xét trong thời gian sớm nhất',
                centered: true
            });
            reset();
        } else {
            Modal.error({
                title: 'Yêu cầu thêm game không thành công',
                content:
                    'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi. Mã lỗi: ' +
                    result.responseCode(),
                centered: true
            });
        }
    };

    return (
        <RequestGameWrapper>
            <RequestGameContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper>
                        <LabelText>Tên game</LabelText>
                        <InputText
                            placeholder=""
                            type="text"
                            {...register('gameName')}
                            isError={errors.gameName ? 'error' : ''}
                        />
                        {errors.gameName ? (
                            <ErrorText>{errors.gameName.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelText>Tên nhà phát hành</LabelText>
                        <InputText
                            placeholder=""
                            type="text"
                            {...register('companyName')}
                            isError={errors.companyName ? 'error' : ''}
                        />
                        {errors.companyName ? (
                            <ErrorText>{errors.companyName.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelText>Đường dẫn tham khảo (nếu có)</LabelText>
                        <InputText
                            {...register('url')}
                            placeholder=""
                            type="text"></InputText>
                    </InputWrapper>

                    <InputWrapper>
                        <LabelText>Ghi chú</LabelText>
                        <InputTextMultiline
                            placeholder=""
                            {...register('note')}
                            type="text"></InputTextMultiline>
                    </InputWrapper>

                    <SendButton typeButton={APP_BUTTON_TYPE.PRIMARY}>
                        Gửi yêu cầu
                    </SendButton>
                </form>
            </RequestGameContainer>
        </RequestGameWrapper>
    );
});
