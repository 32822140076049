import { Modal, Select } from 'antd';
import styled from 'styled-components';
import {
    AppInputText,
    AppInputTextMultiline
} from '../../../styles/AppInput.styles';
import { AppButton } from '../../../styles/AppButton.styles';
import { device } from '../../../configs/MediaQuery';

export const AddTicketDialogWrapper = styled.div``;

export const DialogTitleContainer = styled.div`
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const DialogTitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const AddTicketModal = styled(Modal)`
    .ant-modal-body {
        padding-top: 5px !important;
    }
`;

export const NetStationSelect = styled(Select)`
    .rc-virtual-list {
        z-index: 999999 !important;
    }
`;

export const SendButton = styled(AppButton)`
    width: 100%;
    font-size: 14px !important;
    margin-top: 30px;
`;

export const RatingInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
`;

export const InputText = styled(AppInputText)`
    &.error {
        margin: 0;
    }
`;

export const InputTextMultiline = styled(AppInputTextMultiline)`
    background: transparent;
    margin: 0;
`;

export const LabelText = styled.p`
    font-size: 15px;
    color: #8d9199;
    font-weight: 400;
    margin: 5px;
`;

export const ErrorText = styled.p`
    font-size: 13px;
    line-height: normal;
    color: red;
    margin: 0px;
    margin-top: 5px;
`;

export const ImageUploadWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const ImageUpload = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
`;

export const DeleteUpload = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: #54b4a4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    right: -10px;
    top: -5px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
`;

export const UploadButton = styled.div`
    height: 40px !important;
    font-size: 14px !important;
    background-color: #54b4a4;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: ce;
`;
