import { ComponentType } from 'react';
import pure from 'recompose/pure';
import {
    FilterContentWrapper,
    InputText,
    InputWrapper,
    LabelText,
    SearchButton,
    SearchButtonWrapper,
    StatusTicketFilter,
    TicketFilterContainer,
    TicketFilterWrapper
} from './TicketFilter.styles';
import { Col, Collapse, Row, Select } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { Controller, useForm } from 'react-hook-form';
import { IGetListTicketRequest, ITicketFilter } from '../../../types/ITicket';

//components

//styles

interface Props {
    onFilter: (data: ITicketFilter) => void;
}

const { Panel } = Collapse;

export const TicketFilter: ComponentType<Props> = pure(({ onFilter }) => {
    const store = useRootStore();
    const { getListTicket, ticketFilter, updateTicketFilter } =
        store.ticketStore;

    const {
        register,
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { errors }
    } = useForm<ITicketFilter>({
        defaultValues: {
            ticketId: ticketFilter ? ticketFilter.ticketId : '',
            phone: ticketFilter ? ticketFilter.phone : '',
            status: ticketFilter ? ticketFilter.status : -1 //all netstation filters
        }
    });

    const onSubmit = async (data: ITicketFilter) => {
        updateTicketFilter(data);
        onFilter(data);
        /*const params: IGetListTicketRequest = {
            data: {
                ticketId: data.ticketId,
                phone: data.phone,
                status: data.status
            }
        };
        const result = await getListTicket(params);
        onFilter(result.getDescription());*/
    };

    return (
        <TicketFilterWrapper>
            <TicketFilterContainer defaultActiveKey={1}>
                <Panel header="Tìm kiếm yêu cầu" key={1}>
                    <FilterContentWrapper>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col xl={6} md={12} sm={24} xs={24}>
                                    <InputWrapper>
                                        {/* <LabelText>Mã yêu cầu</LabelText> */}
                                        <InputText
                                            placeholder="Mã yêu cầu"
                                            type="text"
                                            {...register('ticketId')}
                                        />
                                    </InputWrapper>
                                </Col>
                                <Col xl={6} md={12} sm={24} xs={24}>
                                    <InputWrapper>
                                        {/* <LabelText>Điện thoại khách hàng</LabelText> */}
                                        <InputText
                                            placeholder="Điện thoại"
                                            type="text"
                                            {...register('phone')}
                                        />
                                    </InputWrapper>
                                </Col>
                                <Col xl={6} md={12} sm={24} xs={24}>
                                    <InputWrapper>
                                        {/* <LabelText>Điện thoại khách hàng</LabelText> */}
                                        <Controller
                                            control={control}
                                            name="status"
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { invalid, error },
                                                formState
                                            }) => (
                                                <StatusTicketFilter
                                                    defaultValue={-1}
                                                    style={{ width: '100%' }}
                                                    value={value}
                                                    onChange={onChange}
                                                    options={[
                                                        {
                                                            value: -1,
                                                            label: '-- Tất cả yêu cầu --'
                                                        },
                                                        {
                                                            value: 0,
                                                            label: 'Mới tạo'
                                                        },
                                                        {
                                                            value: 1,
                                                            label: 'Đang xử lý'
                                                        },
                                                        {
                                                            value: 3,
                                                            label: 'Đã hoàn thành'
                                                        }
                                                    ]}
                                                />
                                            )}
                                        />
                                    </InputWrapper>
                                </Col>
                                <SearchButtonWrapper
                                    xl={6}
                                    md={12}
                                    sm={24}
                                    xs={24}>
                                    <SearchButton
                                        type="submit"
                                        typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                        Tìm kiếm yêu cầu
                                    </SearchButton>
                                </SearchButtonWrapper>
                            </Row>
                        </form>
                    </FilterContentWrapper>
                </Panel>
            </TicketFilterContainer>
        </TicketFilterWrapper>
    );
});
