import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import md5 from 'blueimp-md5';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Spin, message } from 'antd';
import { ComponentType } from 'react';
import { pure } from 'recompose';

import { ILoginBase, ILoginEmailRequest, LOGIN_TYPE } from '../../types/ILogin';
import { LoginSchema } from '../../validation/LoginSchema';
import { useRootStore } from '../../providers/RootStoreProvider';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

//styles
import {
    LoginWrapper,
    LoginContainer,
    LoginModal,
    LoginTitle,
    TitleContainer,
    LogoApp,
    LoginEmailText,
    LoginInputText,
    LoginButton,
    ForgetPass,
    NoAccountWrapper,
    NoAccountText,
    NoAccountLink,
    SpinLogin
} from './LoginView.styles';

import { APIResponse } from '../../models/APIResponse';
import { ILoginResponseBase, IResponseBase } from '../../types/ITypeBase';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';

interface Props {}

export const LoginView: ComponentType<Props> = pure(
    observer(({}) => {
        const store = useRootStore();
        const { t } = useTranslation();
        const navigation = useNavigate();

        const { login, loadingLogin } = store.authStore;

        const {
            register,
            handleSubmit,
            reset,
            setValue,
            formState: { errors }
        } = useForm<ILoginBase>({
            resolver: yupResolver(LoginSchema)
        });

        const handleForgotPass = () => {};

        const handleRegisterAccount = () => {};

        const onSubmit = async (data: ILoginBase) => {
            const params: ILoginEmailRequest = {
                data: {
                    account: data.account,
                    password: md5(data.password)
                }
            };

            const result: APIResponse<ILoginResponseBase> = await login(params);
            if (result && result.isSuccess()) handleLoginSuccess();
            else handleLoginFailure();
        };

        const handleLoginSuccess = () => {
            message.success('Đăng nhập hệ thống thành công !');
            navigation('/net-station');
        };

        const handleLoginFailure = () => {
            Modal.error({
                title: 'Đăng nhập không thành công',
                content: 'Vui lòng kiểm tra lại thông tin và thử lại',
                centered: true
            });
        };

        return (
            <LoginWrapper>
                <LoginContainer>
                    <TitleContainer>
                        <LoginTitle>Đăng nhập</LoginTitle>
                        <LogoApp src="../../../../images/app_logo.png"></LogoApp>
                    </TitleContainer>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <LoginInputText
                            {...register('account')}
                            //defaultValue="sonnp@fusoftvn.com"
                            isError={errors.account ? 'error' : ''}
                            placeholder="Tên đăng nhập / Email"></LoginInputText>
                        <LoginInputText
                            {...register('password')}
                            type="password"
                            //defaultValue="@dmin"
                            isError={errors.password ? 'error' : ''}
                            placeholder="Mật khẩu"></LoginInputText>
                        {!loadingLogin ? (
                            <LoginButton
                                type="submit"
                                typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                Đăng nhập
                            </LoginButton>
                        ) : (
                            <LoginButton
                                style={{ pointerEvents: 'none' }}
                                typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                <SpinLogin />
                            </LoginButton>
                        )}
                    </form>
                    {/* <ForgetPass onClick={() => handleForgotPass()} href="#">
                        Quên mật khẩu?
                    </ForgetPass>
                    <NoAccountWrapper>
                        <NoAccountText>Bạn chưa có tài khoản</NoAccountText>
                        <NoAccountLink
                            onClick={() => handleRegisterAccount()}
                            href="#">
                            Đăng ký
                        </NoAccountLink>
                    </NoAccountWrapper> */}
                </LoginContainer>
            </LoginWrapper>
        );
    })
);
