export enum APP_BUTTON_TYPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    BORDER = 'border',
    LIGHT = 'light',
    DISABLED = 'disabled',
    TRANSPARENT = 'transparent'
}

export enum MAIN_MENU {
    FEEDBACK,
    NET_STATION_INFO,
    TICKET,
    FFOOD_TICKET
}

export interface ITabMenu {
    id: number;
    title: any;
}
