import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import { IGetListNetStationRequest } from '../types/INetStation';
import BaseAuthService from './BaseAuthService';

export class NetStationService extends BaseAuthService {
    private static instance: NetStationService;

    public static getInstance(): NetStationService {
        if (!NetStationService.instance) {
            NetStationService.instance = new NetStationService();
        }
        return NetStationService.instance;
    }

    getListNetStation = async (params: IGetListNetStationRequest) => {
        try {
            const response = await this.postData(API.NET_STATION.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
