export const API = {
    AUTHENTICATION: {
        LOGIN: 'login',
        FORGOT_PASS: 'login/forgetpwd',
        CHANGE_PASS: 'login/changepwd',
        GET_TOKEN: 'login/auth2'
    },
    USER: {
        PROFILE_DETAIL: 'user-detail',
        FEEDBACK: 'feedback',
        ADD_GAME: 'request-game'
    },
    TICKET: {
        LIST: 'ticket/list',
        ADD_TICKET: 'add-ticket',
        COMMENT_LIST: 'ticket/comment-list',
        ADD_COMMENT: 'ticket/comment',
        RATING: 'ticket/rating',

        FFOOD_LIST: 'ffood/ticket/list',
        FFOOD_COMMENT_LIST: 'ffood/ticket/comment-list',
        FFOOD_ADD_COMMENT: 'ffood/ticket/comment'
    },
    NET_STATION: {
        LIST: 'net-station/list'
    },
    GLOBAL: {
        PRODUCT: 'product',
        UPLOAD_IMAGE: 'webhook/upload'
    },
    JSON: {}
};
