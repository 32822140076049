import { IRequestBase } from './ITypeBase';

export enum TICKET_STATUS {
    ALL = -1,
    NEW,
    PROCESSING,
    CANCEL,
    COMPLETED
}

export enum TICKET_ACTION {
    COMMENT,
    CLOSE,
    ACCEPT,
    CANCEL
}

export enum TICKET_PRODUCT {
    DEFAULT,
    FFOOD
}

export interface ITicketBase {
    ticketId: string;
    netId: string;
    createdBy: string;
    createdDate: string;
    ultraAccount: string; //thông tin remote ultra view
    ultraPass: string;
    status: TICKET_STATUS;
    contentRequest: string; //nội dung yêu cầu hỗ trợ
}

export interface ITicket extends ITicketBase {
    netName: string;
    netCode: string;
    address: string;
    owner: string;
    phone: string;
    cityName: string;
    districtName: string;
    rating: number;
    ratingContent: string; // nội dung đánh giá
    ratingBy: string; // người đánh giá
    ratingDate: string; // ngày đánh giá
    supportBy: string; // thông tin OS hỗ trợ
    supportDate: string; // ngày hỗ trợ
    isRanking: boolean;
    isComment: boolean;
    createDate: string;
    statusName: string;
}

export interface ITicketComment {
    ticketId: string;
    commentId: string;
    userId: string;
    userName: string;
    fullName: string;
    commentDate: string;
    commentContent: string;
}

export interface ITicketDialogData {
    title: string;
    description: string;
    action: TICKET_ACTION;
}

export interface IRatingTicket {
    ticketId: string;
    rating: number;
    ratingContent: string;
    ratingBy: string;
    ratingDate: string;
}

export interface ITicketFilter {
    ticketId: string;
    phone: string;
    status: TICKET_STATUS;
}

export interface IAddTicketRequest extends IRequestBase {
    data: {
        netId: string;
        ultraAccount: string;
        ultraPass: string;
        contentRequest: string;
        image?: string;
        ts?: any;
        token?: string;
    };
}

export interface IAddTicketCommentRequest extends IRequestBase {
    data: {
        ticketId: string;
        commentContent: string;
        status?: number;
    };
}

export interface IRatingTicketRequest extends IRequestBase {
    data: {
        ticketId: string;
        rating: number;
        ratingContent: string;
    };
}

export interface IGetListTicketRequest extends IRequestBase {
    data: {
        ticketId: string;
        phone: string;
        status: TICKET_STATUS;
        from?: number;
        size?: number;
    };
}

export interface IGetListTicketCommentsRequest extends IRequestBase {
    data: {
        ticketId: string;
        from?: number;
        size?: number;
    };
}

export interface IAdminActionTicketRequest extends IRequestBase {
    data: {
        ticketId: string;
        supportBy: string;
        supportNote: string;
        action: TICKET_STATUS;
    };
}
