import { ComponentType, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
//components

//styles
import {
    AvatarImage,
    AvatarWrapper,
    DropdownProfile,
    LogoutButton,
    ProfileMenuItem,
    ProfileMenuWrapper,
    UserLoginContainer,
    UserLoginWrapper
} from './UserLogin.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import { AuthHeader } from '../../../../helpers/AuthHeader';
import { IGetUserProfileDetailRequest } from '../../../../types/IUser';

interface Props {}

export const UserLogin: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const navigate = useNavigate();

    const { currentUser, getProfileDetail, logout } = store.authStore;

    useEffect(() => {
        if (currentUser == null && AuthHeader() != null)
            onGetProfileUserLogin();
    }, []);

    const renderContent = () => {
        if (currentUser?.isLogin()) {
            return <LoginView />;
        }
    };

    const onGetProfileUserLogin = () => {
        let params: IGetUserProfileDetailRequest = {};
        getProfileDetail(params);
    };

    const onUserLogout = () => {
        navigate('/login');
        logout();
    };

    const renderMenuProfile = () => {
        return (
            <ProfileMenuWrapper>
                {/* <ProfileMenuItem>
                    <Link to={'/change-pass'}>
                        <ProfileMenuText>Đổi mật khẩu</ProfileMenuText>
                    </Link>
                </ProfileMenuItem> */}

                <ProfileMenuItem>
                    <LogoutButton
                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                        onClick={() => onUserLogout()}>
                        Đăng xuất
                    </LogoutButton>
                </ProfileMenuItem>
            </ProfileMenuWrapper>
        );
    };

    const LoginView = () => {
        return (
            <UserLoginContainer>
                <AvatarWrapper>
                    <AvatarImage
                        onError={() => alert('error')}
                        src={currentUser?.avatar}
                        placeholder={true}
                        preview={false}
                        fallback="../../../../images/default-avatar.png"></AvatarImage>
                    <DropdownProfile
                        overlay={renderMenuProfile}
                        trigger={['click']}>
                        <Space>
                            {currentUser?.email}
                            <DownOutlined />
                        </Space>
                    </DropdownProfile>
                </AvatarWrapper>
            </UserLoginContainer>
        );
    };

    return <UserLoginWrapper>{renderContent()}</UserLoginWrapper>;
});
