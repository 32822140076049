import { ComponentType, useEffect, useState } from 'react';
import { Pagination, PaginationProps } from 'antd';
//components
import { NetStationItem } from './NetStationItem/NetStationItem';
import { NetStationFilter } from './NetStationFilter/NetStationFilter';

import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import {
    IGetListNetStationRequest,
    INetStationFilter,
    NET_STATION_STATUS
} from '../../types/INetStation';
import { AddTicketDialog } from '../DialogView/AddTicketDialog/AddTicketDialog';
import { NetStation } from '../../models/NetStation';
import { MAIN_MENU } from '../../types/IGlobal';

//styles
import {
    LoadingText,
    LoadingWrapper,
    NetStationListWrapper,
    NetStationTitle,
    PaginationWrapper
} from './NetStationView.styles';
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';
import { Spin } from 'antd';
import { NoData } from '../CommonView/NoData/NoData';

interface Props {}

export const NetStationView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listNetStation, netStationFilter, getListNetStation } =
        store.netStationStore;
    const { changeMainMenuIndex, changeDialogAddTicketActive } =
        store.globalStore;

    const [netStation, setNetStation] = useState<NetStation>();
    const [showAddTicket, setShowAddTicket] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [description, setDescription] = useState('');

    useEffect(() => {
        onGetListNetStation();
        changeMainMenuIndex(MAIN_MENU.NET_STATION_INFO);
    }, []);

    const onGetListNetStation = async (
        isLoadMore?: boolean,
        pageIndex?: number
    ) => {
        const params: IGetListNetStationRequest = {
            data: {
                netId: netStationFilter ? netStationFilter.netId : '',
                netCode: netStationFilter ? netStationFilter.netCode : '',
                status: netStationFilter ? netStationFilter.status : 3
            }
        };
        const result = await getListNetStation(params, isLoadMore, pageIndex);
        setDescription(result.getDescription());
    };

    const onRequestAddTicket = async (data: NetStation) => {
        setNetStation(data);
        changeDialogAddTicketActive(true);
        setShowAddTicket(true);
    };

    const renderLoading = () => {
        if (listNetStation && listNetStation.isLoading)
            return (
                <LoadingWrapper>
                    <Spin></Spin>
                    <LoadingText>Đang tải dữ liệu</LoadingText>
                </LoadingWrapper>
            );
        else return null;
    };

    const renderNoData = () => {
        if (!listNetStation?.hasData() && !listNetStation?.isLoading)
            return <NoData description={description} />;
        else return null;
    };

    const onChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
        onGetListNetStation(true, page);
    };

    const onFilterSuccess = async (data: INetStationFilter) => {
        setPageIndex(1);
        const params: IGetListNetStationRequest = {
            data: {
                netId: data.netId,
                netCode: data.netCode,
                status: data.status
            }
        };
        const result = await getListNetStation(params);
        setDescription(result.getDescription());
    };

    const renderData = () => {
        if (listNetStation && listNetStation.hasData())
            return (
                <NetStationListWrapper>
                    <NetStationTitle>
                        Danh sách phòng máy (
                        {listNetStation?.totalRecord
                            .toString()
                            .padStart(2, '0')}
                        )
                    </NetStationTitle>
                    {listNetStation.list.map((item) => {
                        return (
                            <NetStationItem
                                key={item.netId}
                                data={item}
                                addTicket={onRequestAddTicket}
                            />
                        );
                    })}

                    <PaginationWrapper>
                        <Pagination
                            showSizeChanger={false}
                            defaultCurrent={pageIndex}
                            total={listNetStation.totalPage}
                            onChange={onChange}
                        />
                    </PaginationWrapper>
                </NetStationListWrapper>
            );
        else return null;
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <NetStationFilter onFilter={onFilterSuccess} />
                {renderLoading()}
                {renderData()}
                {renderNoData()}
            </AppContentContainer>
            {netStation && showAddTicket ? (
                <AddTicketDialog
                    netStation={netStation!}
                    visible={showAddTicket}
                    onClose={() => setShowAddTicket(false)}
                />
            ) : null}
        </AppContentWrapper>
    );
});
