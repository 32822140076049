import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { AppInputText } from '../../../styles/AppInput.styles';
import { AppButton } from '../../../styles/AppButton.styles';

export const TicketCommentWrapper = styled.div``;

export const CommentModal = styled(Modal)`
    //background-color: #f0efed;
    .ant-modal-body {
        padding-top: 5px !important;
    }
`;

export const DialogTitleContainer = styled.div`
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const DialogTitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const CommentListWrapper = styled.div`
    max-height: 400px;
    overflow-y: auto;
    //background-color: #f0efed;
    padding: 10px;
    border: 1px solid #f0efed;
    margin-bottom: 20px;
`;

export const CommentInputWrapper = styled.div`
    display: flex;
`;

export const CommentInputText = styled(AppInputText)`
    &.error {
        margin: 0;
    }
`;

export const SendComment = styled(AppButton)`
    height: 40px;
    font-size: 14px !important;
    width: 100px;
    margin-left: 10px;
`;
