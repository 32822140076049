import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';

export const AuthWrapper = styled.div`
    width: 100%;
`;

export const AuthContainer = styled.div`
    @media ${device.mobileS} {
        width: 100%;
        margin: 0 auto;
    }

    @media ${device.tablet} {
        width: 1100px;
        margin: 0 auto;
    }
`;

export const AuthLoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;
