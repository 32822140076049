import { ComponentType, useState } from 'react';
import pure from 'recompose/pure';

//components
import { Rate } from 'antd';
//styles
import {
    DialogTitleContainer,
    DialogTitleText,
    RatingButton,
    RatingDialogWrapper,
    RatingModal,
    RatingStart
} from './RatingDialog.styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IRatingTicket, IRatingTicketRequest } from '../../../types/ITicket';
import { RatingTicketSchema } from '../../../validation/RatingTicketSchema';
import { Modal } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import {
    ErrorText,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    RatingInputWrapper
} from '../AddTicketDialog/AddTicketDialog.styles';
import { useRootStore } from '../../../providers/RootStoreProvider';
import Ticket from '../../../models/Ticket';

interface Props {
    ticket: Ticket;
    visible: boolean;
    onClose: () => void;
}

export const RatingDialog: ComponentType<Props> = pure(
    ({ visible, ticket, onClose }) => {
        const store = useRootStore();
        const { ratingTicket } = store.ticketStore;

        const [value, setValue] = useState(0);

        const {
            register,
            handleSubmit,
            control,
            reset,
            resetField,
            formState: { errors }
        } = useForm<IRatingTicket>({
            resolver: yupResolver(RatingTicketSchema),
            defaultValues: {}
        });

        const onSubmit = async (data: IRatingTicket) => {
            console.log(data);
            const params: IRatingTicketRequest = {
                data: {
                    ticketId: ticket.ticketId,
                    rating: data.rating,
                    ratingContent: data.ratingContent
                }
            };
            const result = await ratingTicket(params);
            if (result && result.isSuccess()) {
                reset();
                Modal.success({
                    title: 'Đánh giá hỗ trợ thành công',
                    content: 'Cám ơn bạn đã đánh giá hỗ trợ của chúng tôi.',
                    centered: true
                });
                ticket.updateRatingStatus(data.rating, data.ratingContent);
                onClose();
                return;
            } else {
                Modal.error({
                    title: 'Đánh giá hỗ trợ không thành công',
                    content:
                        result.getDescription().length > 0
                            ? result.getDescription()
                            : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                    centered: true
                });
            }
        };

        const onChangeRating = () => {};

        return (
            <RatingDialogWrapper>
                <RatingModal
                    centered
                    onCancel={onClose}
                    visible={visible}
                    footer={null}>
                    <DialogTitleContainer>
                        <DialogTitleText>Đánh giá hỗ trợ</DialogTitleText>
                    </DialogTitleContainer>
                    <LabelText>
                        Yêu cầu của bạn đã được hỗ trợ và xử lý hoàn tất. Bạn
                        vui lòng cho chúng tôi biết đánh giá của bạn về sự hỗ
                        trợ này
                    </LabelText>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <RatingInputWrapper>
                            <Controller
                                control={control}
                                name="rating"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { invalid, error },
                                    formState
                                }) => (
                                    <RatingStart
                                        onChange={(e: any) => onChange(e)}
                                        value={value}
                                    />
                                )}
                            />
                            {errors.rating ? (
                                <ErrorText>{errors.rating.message}</ErrorText>
                            ) : null}
                        </RatingInputWrapper>

                        <InputWrapper>
                            <LabelText>Nội dung đánh giá</LabelText>
                            <InputTextMultiline
                                placeholder=""
                                type="text"
                                {...register('ratingContent')}
                                isError={errors.ratingContent ? 'error' : ''}
                            />
                            {errors.ratingContent ? (
                                <ErrorText>
                                    {errors.ratingContent.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>

                        <RatingButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            type="submit">
                            Gửi đánh giá
                        </RatingButton>
                    </form>
                </RatingModal>
            </RatingDialogWrapper>
        );
    }
);
